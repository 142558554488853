import React from 'react';
import { Button } from '@webfx/core-web';

import styles from './HeaderPan.module.css';

const HeaderNavigation = ({ appsRef }) => {
  return (
    <div className={`${styles.navButtonsWrapper} mr-2`}>
      <Button
        className={styles.navButton}
        onClick={() => {
          appsRef.current.scrollBy({
            left: -100,
            behavior: 'smooth',
          });
        }}
        variant="primary"
        icon="keyboard_arrow_left"
      />
      <Button
        className={styles.navButton}
        onClick={() => {
          appsRef.current.scrollBy({
            left: 100,
            behavior: 'smooth',
          });
        }}
        variant="primary"
        icon="keyboard_arrow_right"
      />
    </div>
  );
};

export default HeaderNavigation;

import { store } from '@webfx/core-web';

store.dispatch.router.registerRoutes([
  {
    name: 'cgfxKanban',
    url: '/contentgenius/:siteId?',
  },
  {
    name: 'cgfxReview',
    url: '/contentgenius/:siteId/review/:copyPageId?',
  },
  {
    name: 'cgfxContentPlanner',
    url: '/contentgenius/:siteId/:page(content-planner|draftbox)?/:assetId?',
  },
]);

import classnames from 'classnames';
import { Icon, Link } from '@webfx/core-web';
import { useRouter } from '@webfx/web-hooks';

import useHelpCategory from '@webfx/common-web/src/hooks/help-center/useHelpCategory.js';
import { buildArticleUrl } from '@webfx/common-web/src/ui/pages/HelpCenter/utils/utils.js';

import styles from './SideMenu.module.css';

const SideMenu = ({ headers, onItemClick }) => {
  const {
    url,
    params: { categoryId, categoryName, articleId },
  } = useRouter();
  const { data } = useHelpCategory();

  const articles = data?.articles?.filter((article) => article.slug.split('/')[0] !== articleId);

  return (
    <nav className={styles.sideMenu}>
      {headers?.length ? (
        <div className="mb-3 bg-gray-100 rounded">
          <div
            className={classnames(styles.header, 'd-flex align-items-center font-20 p-3 semibold')}
          >
            <Icon outlined>toc</Icon>
            <span className="ml-2">In this article</span>
          </div>
          <ul className={classnames(styles.menuList, 'p-3')}>
            {headers.map((header) => (
              <li key={header.id} className={styles.menuItem}>
                <button
                  onClick={() => onItemClick(header.id)}
                  className="text-gray-600 text-hover-gray-700 font-14"
                >
                  {header.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      {articles?.length ? (
        <>
          <div className="d-flex align-items-center font-20 px-3 pt-3 semibold">
            <Icon outlined>article</Icon>
            <span className="ml-2">More in this category</span>
          </div>
          <ul className={classnames(styles.menuList, 'p-3')}>
            {articles.map((article) => (
              <li key={article.id} className={styles.menuItem}>
                <Link
                  to={buildArticleUrl({ url, article, category: { categoryId, categoryName } })}
                  className="text-gray-550 text-hover-blue-vivid-400"
                >
                  {article.name}
                </Link>
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </nav>
  );
};

export default SideMenu;

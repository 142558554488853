/**
 * Check if the report name is an Executive Summary report
 * @param {string} name
 * @returns {boolean}
 */
function isExecSummaryReport(name) {
  const lowerCaseName = name?.toLowerCase() || '';
  if (lowerCaseName.includes('executive summary') || lowerCaseName.includes('exec summary')) {
    return true;
  }
  return false;
}

module.exports = isExecSummaryReport;

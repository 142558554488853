import { useAuth } from '@webfx/web-hooks';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { email as emailSchema, password as passwordSchema } from '../utils/validators';

const validationSchema = Yup.object().shape({
  email: emailSchema,
  password: passwordSchema,
});

export function useLogin() {
  const login = useAuth().login;

  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(false);

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const code = params.get('code');
  const provider = params.get('provider');

  const form = {
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validationSchema,

    onSubmit: async (values, actions) => {
      try {
        setDisabled(true);
        actions.setSubmitting(true);
        actions.setStatus(null);
        setError(null);
        maybeForceGoogleAuth(values.email);
        await login({ strategy: 'local', ...values });
      } catch (error) {
        const message = extractErrorMessage(error);
        actions.setStatus(message);
        setError(message);
      } finally {
        actions.setSubmitting(false);
        setDisabled(false);
      }
    },
    displayName: 'Sign In Form',
  };

  const handleOffice365Login = useCallback(
    async (code) => {
      setDisabled(true);

      try {
        await login({ strategy: 'office365', code });
      } catch (error) {
        setError(error.message);
        setDisabled(false);
      }
    },
    [login, setDisabled, setError]
  );

  useEffect(() => {
    if (code && provider === 'office365') {
      handleOffice365Login(code);
    }
  }, [code, handleOffice365Login, provider]);

  return { form, error, disabled, setError, setDisabled };
}

function extractErrorMessage(error) {
  const extractedErr = error.message || error;

  switch (extractedErr) {
    case 'Invalid login':
      return 'The entered Email or Password is incorrect.';

    case 'Invalid ip address':
      return 'This user cannot access the server from this IP address.';

    case 'Password expired':
      return `Your password hasn't been updated in over 6 months. Please use the "Forgot
          Password" feature to pick a new one and log into the application, or use
          our Sign in with Google option.`;

    case 'Sign in using Google':
    case 'Organization requires that you use an SSO Provider to login.':
    case 'No active account found for this email.':
      return extractedErr;

    default:
      return 'Unable to login at this time, please contact your account manager.';
  }
}

function maybeForceGoogleAuth(email) {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }
  const [username, domain] = email.split('@');
  if (domain === 'webfx.com' && !username.includes('+')) {
    throw new Error('Sign in using Google');
  }
}

import classnames from 'classnames';
import { Svg } from '@webfx/core-web';
import ForegroungBg from '../../../../assets/images/help-center/foreground.svg';
import SearchBar from './SearchBar';
import styles from './Header.module.css';

const Header = () => {
  return (
    <div
      className={classnames(
        styles.header,
        'd-flex flex-column position-relative align-items-center'
      )}
    >
      <div className="d-flex flex-column align-items-center">
        <Svg src={ForegroungBg} className={classnames(styles.foregroundBg)} />
        <SearchBar />
      </div>
      <div className="d-flex flex-column w-100 align-items-center" style={{ marginTop: '-50px' }}>
        <h1 className="font-32 bold text-blue-400">WebFX Client Knowledge Center</h1>
        <h2 className="font-14 text-gray-600 m-0 bold">
          Insider articles, webinars, and other exclusive resources, updated monthly to help you
          push past the competition
        </h2>
      </div>
    </div>
  );
};

export default Header;

import { useDocumentTitle } from '@webfx/web-hooks';

import RecentlyAdded from './RecentlyAdded';
import CategoryList from './CategoryList';

const Categories = () => {
  useDocumentTitle('WebFX Client Knowledge Center');

  return (
    <div className="d-flex flex-column pb-4">
      <RecentlyAdded />
      <CategoryList />
    </div>
  );
};

export default Categories;

import * as Yup from 'yup';

const errors = {
  email: {
    incorrect: 'Email address must be valid (format: john@doe.com)',
    length: 'Email Address must be no longer than 100 characters',
  },
  password: {
    regexp:
      'Password should be min 10 characters, Lowercase, Uppercase, Numeric, Special Characters.',
    match: 'Password and Confirm password should be the same',
    confirm: 'Password confirm is required',
  },
  required: 'Field is required',
};

export const email = Yup.string()
  .email(errors.email.incorrect)
  .required(errors.required)
  .max(100, errors.email.length);

export const newEmail = Yup.string().email(errors.email.incorrect).max(100, errors.email.length);

// min 10 characters, Lowercase, Uppercase, Numeric, Special Characters
const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/;

export const password = Yup.string().required(errors.required);

export const createPassword = Yup.string()
  .matches(passwordRegexp, errors.password.regexp)
  .required(errors.required);

export const confirmPassword = Yup.string()
  .oneOf([Yup.ref('password'), null], errors.password.match)
  .required(errors.password.confirm);

export const editPassword = Yup.string().matches(passwordRegexp, errors.password.regexp);

export const editPasswordConfirmation = Yup.string().when('password', {
  is: (val) => val,
  then: Yup.string()
    .oneOf([Yup.ref('password')], errors.password.match)
    .required(errors.password.match),
});

import classnames from 'classnames';

import { Svg, Spinner, BreadCrumb } from '@webfx/core-web';
import { useDocumentTitle } from '@webfx/web-hooks';
import useHelpBreadCrumbs from '@webfx/common-web/src/hooks/help-center/useHelpBreadCrumbs.js';
import useHelpCategory from '@webfx/common-web/src/hooks/help-center/useHelpCategory.js';
import GuruAdminCTA from '@webfx/common-web/src/ui/components/GuruAdminCTA/GuruAdminCTA.jsx';

import { categoriesMap } from '../../constants/categories.js';
import ArticleCard from '../../Articles/ArticleCard';
import CategoryCard from '../../Categories/CategoryCard';

import styles from './CategoryPage.module.css';

const CategoryPage = () => {
  const { data, isFetching } = useHelpCategory();

  const categoryName = data?.folder?.name;
  const category = data ? categoriesMap[categoryName] : null;

  useDocumentTitle(`${categoryName || 'Help Category'} | WebFX Client Knowledge Center`);

  const items = data?.folder ? [data.folder.parentFolder, data.folder] : [];

  const placeholder = categoriesMap['placeholder'];

  const breadcrumbItems = useHelpBreadCrumbs(items);

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner small />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column pb-3">
      <BreadCrumb items={breadcrumbItems} />
      {/* Banner */}
      <div className="position-relative d-flex align-items-center mb-3">
        <span className="text-white bold font-32 position-absolute ml-5">{data?.folder.name}</span>
        {category ? (
          <Svg src={category.banner} className="align-items-center" />
        ) : (
          <Svg src={placeholder.banner} className="align-items-center" />
        )}
      </div>
      <GuruAdminCTA
        link={`https://app.getguru.com/folders/${data?.folder.slug}`}
        className="mb-3"
      />

      {/* SubCategories */}
      {data?.subCategories?.length > 0 && (
        <>
          <h3 className="text-gray-550 bold font-24 mb-3">Subcategories</h3>
          <div className={classnames(styles.categoryContainer, 'mb-4')}>
            {(data.subCategories ?? []).map((sub) => (
              <CategoryCard key={sub.id} category={sub} />
            ))}
          </div>
        </>
      )}

      {/* Articles list */}
      {data?.articles?.length > 0 && <h3 className="text-gray-550 bold font-24 mb-3">Articles</h3>}
      <div className={styles.articleContainer} data-fx-name="articlesList">
        {(data?.articles ?? []).map((article) => (
          <ArticleCard key={article.id} article={article} category={data.folder} />
        ))}
      </div>
    </div>
  );
};

export default CategoryPage;

import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { ProductLogo } from '@webfx/web-ui';

import styles from './HeaderTab.module.css';

const HeaderTab = ({ to, isActive, text, extraIcon }) => {
  return (
    <div
      className={classnames('header-tab', styles.tab, {
        [styles.activeTab]: isActive,
        active: isActive,
      })}
      data-fx-name={text}
    >
      <NavLink to={to} className={styles.navlink}>
        <ProductLogo product={text} variant="top-nav" className="nav-product-logo" />
        {extraIcon}
      </NavLink>
    </div>
  );
};

export default HeaderTab;

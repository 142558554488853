import React, { useEffect } from 'react';

/**
 *  GoogleSignInButton
 *
 * @description This component renders a Google Sign In button
 * @returns {React.ReactElement}
 */
export function GoogleSignInButton() {
  const [googleLoaded, setGoogleLoaded] = React.useState(false);

  useEffect(() => {
    const initilizeGoogleSignIn = () => {
      if (typeof google === 'undefined') {
        return;
      }
      /* eslint-disable no-undef -- google is a global variable */
      google.accounts.id.initialize({
        client_id: process.env.POI_APP_MCFX_GOOGLE_CLIENT_ID,
        callback: window.handleGoogleCredentialResponse,
      });

      google.accounts.id.renderButton(document.getElementById('googleSignIn'), {
        /* eslint-enable -- enabling no-undef */
        type: 'standard',
        theme: 'outline',
        size: 'large',
        shape: 'rectangular',
        width: 245,
        logo_alignment: 'right',
      });
      setGoogleLoaded(true);
    };

    if (typeof google !== 'undefined') {
      initilizeGoogleSignIn();
      return;
    }

    const gsiScript = document.getElementById('google-gsi-client');
    gsiScript.addEventListener('load', initilizeGoogleSignIn);
    return () => {
      gsiScript.removeEventListener('load', initilizeGoogleSignIn);
    };
  }, []);

  return <div id="googleSignIn" hidden={!googleLoaded} className={`mb-2 mx-auto `}></div>;
}

import { DateTime } from 'luxon';
import { convert } from 'html-to-text';

export const buildArticleUrl = ({ url, article, category = null }) => {
  const { slug, boards } = article;

  const [articleId, articleName] = slug.split('/');
  const categoryId =
    category?.categoryId ?? category?.slug?.split('/')[0] ?? boards?.[0].slug.split('/')[0];
  const categoryName =
    category?.categoryName ?? category?.slug?.split('/')[1] ?? boards?.[0].slug.split('/')[1];

  return url({
    route: 'helpCenterArticle',
    categoryId,
    categoryName,
    articleId,
    articleName,
  });
};

export const formatDate = (date) => {
  const dt = DateTime.fromISO(date);
  const month = dt.month;
  // Use full month name for dates between March and July
  const format = month >= 3 && month <= 7 ? 'MMMM d, yyyy' : 'MMM d, yyyy';
  return dt.toFormat(format);
};

export const truncateText = ({ content, maxLength = 200 }) => {
  if (!content) {
    return '';
  }

  const urlRegex =
    /(?<!\S)(\[?https?:\/\/[^\s\]]+\]?(?!\S)|(?<!\S)\w+[-\w]*\.\w+\s*\[https?:\/\/[^\]]+\])/gi;

  const options = {
    selectors: [
      { selector: 'img', format: 'skip' },
      { selector: 'a', options: { ignoreHref: true } },
    ],
    uppercaseHeadings: false,
    wordwrap: false,
  };

  let strippedHtml = convert(content, options);

  if (strippedHtml.length > maxLength) {
    strippedHtml = strippedHtml.substring(0, maxLength).trim() + '...';
  }

  return strippedHtml.replace(urlRegex, '').trim();
};

import useHelpArticles from '@webfx/common-web/src/hooks/help-center/useHelpArticles.js';
import GuruAdminCTA from '@webfx/common-web/src/ui/components/GuruAdminCTA/GuruAdminCTA.jsx';

import ArticleCard from '../../Articles/ArticleCard/index.js';

import styles from './RecentlyAdded.module.css';

const RecentlyAdded = () => {
  const { data: articles } = useHelpArticles({
    query: {
      sortField: 'dateCreated',
      sortOrder: 'desc',
      maxResults: 4,
    },
  });

  return (
    <>
      <h3 className="text-gray-500 bold font-24 mb-3">Recently Added</h3>

      <GuruAdminCTA
        link="https://app.getguru.com/collections/8iz13/Client-Knowledge-Center"
        className="mb-3"
      />
      <div className="d-flex flex-column pb-3">
        {/* Articles list */}
        <div className={styles.articleContainer}>
          {(articles ?? []).map((article) => (
            <ArticleCard key={article.id} fromMostRecent={true} article={article} />
          ))}
        </div>
      </div>
    </>
  );
};

export default RecentlyAdded;

import { useState, useCallback } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

import { api, Icon, Avatar, BreadCrumb } from '@webfx/core-web';
import { useDocumentTitle } from '@webfx/web-hooks';
import useHelpArticle from '@webfx/common-web/src/hooks/help-center/useHelpArticle';
import useHeaderTracking from '@webfx/common-web/src/hooks/help-center/useHeaderTracking';
import useHelpBreadCrumbs from '@webfx/common-web/src/hooks/help-center/useHelpBreadCrumbs';
import useHelpCategory from '@webfx/common-web/src/hooks/help-center/useHelpCategory.js';
import GuruAdminCTA from '@webfx/common-web/src/ui/components/GuruAdminCTA';

import { formatDate } from '@webfx/common-web/src/ui/pages/HelpCenter/utils/utils.js';

import ArticleShareLink from '../ArticleShareLink';
import ArticleNavigationLinks from '../ArticleNavigationLinks';
import SideMenu from '../../SideMenu';
import Content from './Content';

import styles from './ArticlePage.module.css';

const ArticlePage = () => {
  const { categoryName } = useParams();
  const [contentElement, setContentElement] = useState(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { data: article, isFetching } = useHelpArticle();
  const { data: helpCategory } = useHelpCategory();
  const { headers, scrollToHeader } = useHeaderTracking(contentElement, article?.content);

  const parentCategory = helpCategory?.folder?.parentFolder ?? {};
  // This is to get category for the `Recently Added` articles
  const getCategoryFromBoard = () => {
    return article?.boards?.find((board) => board.slug.includes(categoryName)) ?? {};
  };
  const category = helpCategory?.folder ?? getCategoryFromBoard();

  const items = article
    ? [parentCategory, category, { article: { name: article.name, slug: article.slug } }]
    : [];

  const breadcrumbItems = useHelpBreadCrumbs(items);

  useDocumentTitle(`${article?.name || 'Help Article'} | WebFX Client Knowledge Center`);

  const contentRef = useCallback(
    (node) => {
      if (article) {
        setContentElement(node);
        setContentLoaded(true);
      }
    },
    [article]
  );

  const { isLoading: isDownloading, mutateAsync: generatePDF } = api.useMutation([
    '/help-articles/download',
  ]);

  const downloadArticle = async () => {
    const articleParam = article.id ?? article.slug;
    try {
      const response = await generatePDF({
        _method: 'create',
        articleId: articleParam,
      });

      const blob = new Blob([new Uint8Array(response.generatedPDF.data).buffer]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${article?.name || 'Help Article'}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  };

  if (isFetching || !article) {
    return null;
  }

  const { firstName, lastName, email, image } = article.lastModifiedBy || {};
  const lastModifiedBy = firstName && lastName ? `${firstName} ${lastName}` : email;
  const lastModified = formatDate(article?.lastModified);

  return (
    <div className="d-flex flex-column pb-4">
      <BreadCrumb items={breadcrumbItems} />

      <div className={classnames(styles.articleContainer, 'row mb-5')}>
        <div className="col-3">
          {contentLoaded && <SideMenu headers={headers} onItemClick={scrollToHeader} />}
        </div>
        <div className={classnames('col-9 d-flex flex-column rounded', styles.articleSection)}>
          <div className="pt-2">
            <div className="d-flex mb-2">
              <h2 className="m-0 font-32 bold" data-fx-name="articleName">
                {article.name}
              </h2>
              <div className="flex-shrink-0 ml-auto">
                <GuruAdminCTA link={`https://app.getguru.com/card/${article.slug}`} edit={true} />
              </div>
            </div>

            <div className="d-flex align-items-center text-gray-500 font-14">
              <Avatar src={image} className={classnames(styles.avatar, 'mr-2')} />
              <span className="ml-1 semibold">{lastModifiedBy}</span>
              <Icon className="font-18 ml-1">update</Icon>
              <span className="ml-1">Updated {lastModified}</span>
              <ArticleShareLink className="ml-3" category={category} article={article}>
                <div className="d-flex align-items-center semibold">
                  <Icon
                    className="font-18 text-blue-vivid-300 text-hover-blue-vivid-500"
                    data-fx-name="share"
                    outlined
                  >
                    share
                  </Icon>
                </div>
              </ArticleShareLink>
              <div className="d-flex align-items-center ml-3 font-24">
                <Icon
                  className="cursor-pointer text-blue-vivid-300 text-hover-blue-vivid-500"
                  outlined
                  onClick={downloadArticle}
                >
                  picture_as_pdf
                </Icon>
                {isDownloading && (
                  <span className="ml-2 font-14 text-gray-500">Downloading PDF...</span>
                )}
              </div>
            </div>
          </div>
          <div ref={contentRef} className="py-3" data-fx-name="articleContent">
            <Content content={article.content} className={styles.content} />
          </div>
        </div>
      </div>
      <ArticleNavigationLinks />
    </div>
  );
};

export default ArticlePage;

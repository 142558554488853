import { NavLink } from 'react-router-dom';
import { Typography } from '@webfx/core-web';
import classnames from 'classnames';

import styles from './HeaderLink.module.css';

const HeaderLink = ({ to, className, children }) => {
  if (to.includes('http', 0)) {
    return (
      <a href={to} className={classnames([className, styles.link])}>
        <Typography component="span" variant="h4">
          {children}
        </Typography>
      </a>
    );
  }

  return (
    <NavLink
      to={to}
      className={classnames([className, styles.link])}
      activeClassName={classnames([styles.linkActive])}
    >
      <Typography component="span" variant="h4">
        {children}
      </Typography>
    </NavLink>
  );
};

export default HeaderLink;

const isPlainObject = require('lodash/isPlainObject');
/**
 * Replaces escape characters with "'"
 * @param {String} value
 * @param {String} objectKey
 * @returns {String}
 */
module.exports = function escapeCharacters(value = '', objectKey = 'value') {
  const val = isPlainObject(value) ? value?.[objectKey] : value;

  if (typeof val !== 'string') {
    return value;
  }

  if (typeof String.prototype.replaceAll === 'undefined') {
    String.prototype.replaceAll = function (match, replace) {
      return this.replace(new RegExp(match), () => replace);
    };
  }

  // eslint-disable-next-line quotes
  return val?.replaceAll(/â/g, "'");
};

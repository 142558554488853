import { ActionBar, Button, mixins, Table } from '@webfx/core-web';
import { useAuth, useLocalStore } from '@webfx/web-hooks';
import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import columns from './columns';

function Tokens() {
  const user = useAuth().user;
  const userId = user?.userId || null;

  const [state, actions] = useLocalStore(
    () => ({
      ...mixins.fetchable('tokens', 'tokenId'),
      ...mixins.editable('token', 'tokenId'),
      ...mixins.filterable(),
      filters: {
        userId,
      },
    }),
    [userId],
    { name: 'MyInfoTokens' }
  );

  const createToken = () => {
    actions.create({
      token: uuid(),
      userId,
      description: '',
    });
  };

  const updateToken = (payload) => {
    const { values } = payload;
    actions.save({
      id: values.tokenId,
      description: values.description,
    });
  };

  const removeToken = (payload) => {
    const { id } = payload;
    actions.remove(id);
  };

  useEffect(() => {
    actions.find();
  }, [userId]);

  return (
    <>
      <ActionBar>
        <Button
          variant="primary"
          text="New API Key"
          uppercase
          type="submit"
          icon="add"
          onClick={createToken}
        />
      </ActionBar>

      <Table
        columns={columns}
        data={state.items}
        borderlessHeader
        options={{
          createToken,
          updateToken,
          removeToken,
        }}
      />
    </>
  );
}

export default Tokens;

/**
 * Standard CRM statuses used by
 * - LeadManagerFX
 * - CallTrackerFX
 */
const getTouchpointStatusOptions = () => {
  // status options
  const statusOptions = [
    {
      label: 'Uncategorized',
      value: 'uncategorized',
      actualValue: 'uncategorized',
      isActive: false,
      dotColor: '#ABB9C2',
    },
    {
      label: 'Subscription',
      value: 'subscription',
      actualValue: 'subscription',
      isActive: false,
      dotColor: '#FFD12D',
    },
    {
      label: 'New Lead',
      value: 'new lead',
      actualValue: 'new lead',
      isActive: false,
      dotColor: '#FFD12D',
    },
    {
      label: 'Lead Follow-Up',
      value: 'lead follow-up',
      actualValue: 'lead follow-up',
      isActive: false,
      dotColor: '#FFD12D',
    },
    {
      label: 'Spam',
      value: 'spam',
      actualValue: 'spam',
      isActive: false,
      dotColor: '#f44336',
    },
    {
      label: 'Disqualified',
      value: 'disqualified',
      actualValue: 'disqualified',
      isActive: false,
      dotColor: '#F87476',
    },
    {
      label: 'Upsell Request',
      value: 'upsell request',
      actualValue: 'upsell request',
      isActive: false,
      dotColor: '#B6E684',
    },
    {
      label: 'Downgrade / Cancellation Request',
      value: 'downgrade / cancellation request',
      actualValue: 'downgrade / cancellation request',
      isActive: false,
      dotColor: '#FFA232',
    },
    {
      label: 'Support / Question',
      value: 'support / question',
      actualValue: 'support / question',
      isActive: false,
      dotColor: '#84B9F5',
    },
    { label: 'MQL', value: 'mql', actualValue: 'mql', isActive: false, dotColor: '#7CB342' },
    {
      label: 'Prospect / SQL',
      value: 'prospect',
      actualValue: 'prospect',
      isActive: false,
      dotColor: '#7CB342',
    },
    {
      label: 'Closed Won',
      value: 'sale',
      actualValue: 'sale',
      isActive: false,
      dotColor: '#41D58C',
    },
    {
      label: 'Closed Lost',
      value: 'lost',
      actualValue: 'lost',
      isActive: false,
      dotColor: '#F87476',
    },
  ];

  // results
  return statusOptions;
};

module.exports = getTouchpointStatusOptions;

import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import qs from 'query-string';
import { api } from '@webfx/core-web';

import { createPassword, confirmPassword } from '../utils/validators';

const validationSchema = Yup.object().shape({
  password: createPassword,
  confirmPassword,
});

/**
 * Custom hook for password reset functionality.
 * @returns {Object} An object containing the form, isSubmitting, error, and isError properties.
 */
export function usePasswordConfirmation() {
  const history = useHistory();
  const { search = '' } = useLocation();
  const { token } = useMemo(() => qs.parse(search), [search]);

  const mutator = api.useMutation(['confirm-password'], {
    onSuccess: () => {
      history.replace('/signin?reset-successful');
    },
    onError: (error) => {
      toast.error(error.message || error);
    },
  });

  const form = {
    initialValues: { password: '', confirmPassword: '' },
    validationSchema,
    onSubmit: (values) => {
      mutator.mutate({
        _method: 'create',
        ...values,
        token,
      });
    },
  };

  return { form, isSubmitting: mutator.isLoading, error: mutator.error, isError: mutator.isError };
}

import { api } from '@webfx/core-web';
import { useRouter } from '@webfx/web-hooks';

/**
 * Web hook to get an article.
 * @returns {object} The article.
 */
const useHelpArticle = () => {
  const {
    params: { articleId },
  } = useRouter();

  return api.useQuery([`help-articles/${articleId}`], {
    staleTime: 1000 * 60 * 15, // 15 min
    cacheTime: 1000 * 60 * 30, // 30 min
    enabled: !!articleId,
  });
};

export default useHelpArticle;

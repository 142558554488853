const camelcaseObjectDeep = require('camelcase-object-deep');
/**
 * Converts object keys recursively to camelCase
 * @param {Object} obj
 * @returns {Object} obj - object keys converted to camelCase
 */
function camelCaseKeys(obj) {
  return camelcaseObjectDeep(obj);
}

module.exports = camelCaseKeys;

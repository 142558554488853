import { useMemo } from 'react';
import DOMPurify from 'dompurify';
import linkifyHtml from 'linkify-html';

const Content = ({ content, ...passThroughProps }) => {
  const processedContent = useMemo(() => {
    const sanitized = DOMPurify.sanitize(content, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allowfullscreen', 'frameborder', 'src', 'class', 'style', 'id'],
    });

    const linkified = linkifyHtml(sanitized);

    const parser = new DOMParser();
    const doc = parser.parseFromString(linkified, 'text/html');

    doc.querySelectorAll('iframe').forEach((iframe) => {
      iframe.style.width = '100%';
      iframe.style.minHeight = '400px';
      iframe.style.border = 'none';
    });

    doc.querySelectorAll('h1, h2, h3').forEach((header, index) => {
      header.id = `header-${index}`;
    });

    doc.querySelectorAll('a').forEach((a) => {
      a.setAttribute('target', '_blank');
      a.setAttribute('rel', 'noopener noreferrer');
    });

    return doc.body.innerHTML;
  }, [content]);

  return <div {...passThroughProps} dangerouslySetInnerHTML={{ __html: processedContent }} />;
};

export default Content;

import { useAuth } from '@webfx/web-hooks';
import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';

export function Logout() {
  const logout = useAuth().logout;
  const setLastViewed = useStoreActions((actions) => actions.router.setLastViewed);

  useEffect(() => {
    logout();
    setLastViewed(null);
  }, []);

  return null;
}

import classnames from 'classnames';

import { Svg, Link } from '@webfx/core-web';
import { useRouter } from '@webfx/web-hooks';

import { categoriesMap } from '../../constants/categories.js';

import styles from './CategoryCard.module.css';

const CategoryCard = ({ category }) => {
  const { url } = useRouter();
  const { id, name, slug } = category;
  const [categoryId, categoryName] = slug.split('/');
  const placeholder = categoriesMap['placeholder'];

  return (
    <Link
      to={url({
        route: 'helpCenterCategory',
        categoryId,
        categoryName,
      })}
      className={classnames(
        styles.categoryCardLink,
        'bold font-20 text-blue-700 text-hover-blue-500 p-2 rounded'
      )}
    >
      <div key={id} className="d-flex align-items-center" data-fx-name="categoryCard">
        <div className="flex-shrink-0">
          {categoriesMap[name]?.icon ? (
            <Svg src={categoriesMap[name].icon} svgClassName={styles.svg} />
          ) : (
            <Svg src={placeholder.icon} svgClassName={styles.svg} />
          )}
        </div>
        <span className="pl-3">{name}</span>
      </div>
    </Link>
  );
};

export default CategoryCard;

const groupBy = require('lodash/groupBy');

/**
 * Extension of lodash/groupBy that builds a multi-level grouping.
 *
 * Example usage (see tests for more examples):
 *  INPUT: groupByMulti([
 *    {a:1, b:2, c:1},
 *    {a:1, b:2, c:2},
 *    {a:1, b:3, c:1},
 *    {a:2, b:2, c:5}], ['a', 'b'])
 *  OUTPUT: {
 *    "1": {
 *      "2": [{a:1, b:2, c:1}, {a:1, b:2, c:2}],
 *      "3": [{a:1, b:3, c:1}] },
 *    "2": {
 *      "2": [{a:2, b:2, c:5}] }
 *  }
 *
 * @param {Object|Object[]} collection The collection to iterate over.
 * @param {Function[]|String[]} iteratees The iteratees to transform keys, defining each group
 *  level.
 * @returns {Object} Returns the composed aggregate object.
 */
function groupByMulti(collection, iteratees) {
  // ensure array
  if (!Array.isArray(iteratees)) {
    /* eslint-disable no-param-reassign */
    iteratees = [iteratees];
  }

  // base case
  if (!iteratees?.length) {
    return collection;
  }

  const results = {};
  for (const [groupKey, rows] of Object.entries(groupBy(collection, iteratees[0]))) {
    results[groupKey] = groupByMulti(rows, iteratees.slice(1));
  }
  return results;
}

module.exports = groupByMulti;

/**
 * Function takes a string and capitalizes the first letter of each word
 * @param {String} str String that will be converted to title-case
 * @returns {String} str String converted to title-case
 */
module.exports = function titleCase(str = '') {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

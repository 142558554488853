/**
 * A utility to help check if the current environment is
 * 'test', 'tests', 'staging', 'development'
 * but when 'false' is passed, checks
 * if only CYPRESS e2e tests are running
 *
 * @param {boolean | string} checkTestOnly - defaults to false, helps determine if we only want to check * for test envs
 * @returns {boolean} - 'true' if cypress tests or the NODE_ENV is tests, false if none
 */
const isCypressOrTestsActive = (checkTestOnly = false) => {
  const envs = ['test', 'tests', 'staging', 'development'];

  if (typeof checkTestOnly === 'boolean') {
    if (!checkTestOnly && (process.env.CYPRESS || window?.Cypress)) {
      return true;
    }

    if (checkTestOnly && envs.includes(process.env.NODE_ENV)) {
      return true;
    }
  }

  if (typeof checkTestOnly === 'string') {
    if (process.env.NODE_ENV === checkTestOnly.toString().toLowerCase()) {
      return true;
    }
  }

  return false;
};

module.exports = isCypressOrTestsActive;

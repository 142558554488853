import { PageContainer } from '@webfx/core-web';
import { useAuth } from '@webfx/web-hooks';

const FullPageLayout = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return null;
  }

  return <PageContainer>{children}</PageContainer>;
};

export default FullPageLayout;

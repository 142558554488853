/**
 * Retrieves the company name from the given lead.
 * If the company name is not found, it falls back to the organization name or the default name.
 * @param {*} lead - The lead object containing the company and organization names.
 * @param {*} defaultName - The default name to use if the company and organization names are not found.
 * @returns - The retrieved company name or the default name.
 */
function getLeadCompanyName(lead, defaultName = '') {
  let companyName = null;

  if (!lead) {
    return defaultName;
  }

  if (lead.entity === 'form') {
    const companyInput = lead.payload?.data?.mapped?.find((input) =>
      input.types?.includes('company')
    );
    companyName = companyInput?.value;
  }

  if (!companyName) {
    companyName = lead.company?.name ?? lead.organization;
  }

  return companyName ?? defaultName;
}

module.exports = getLeadCompanyName;

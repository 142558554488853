import classnames from 'classnames';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { Button, Typography, FormikInput } from '@webfx/core-web';
import { usePasswordReset } from '../../hooks/usePasswordReset';

import styles from './ForgotPasswordForm.module.css';

export function ForgotPasswordForm({ onCancel }) {
  const reset = usePasswordReset();

  return (
    <div className="container px-5">
      <Typography
        component="h1"
        variant="jumboTitle1"
        className={classnames(['text-center', styles.title])}
      >
        Reset Your Password
      </Typography>
      <Typography
        component="h2"
        variant="h3"
        className={classnames(['text-center', styles.subtitle])}
      >
        Enter your email address, and we&apos;ll email you instructions for resetting your password.
      </Typography>

      <Formik {...reset.form}>
        {() => (
          <Form>
            <FormikInput
              className="mb-3"
              type="email"
              name="email"
              placeholder="Email Address"
              label="Email Address"
              labelPosition="embed"
              errorVisible={false}
              icon="email"
              iconOutined
              iconPosition="right"
              variant="primary"
            />
            <Row>
              <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 1 }}>
                <Button
                  className="mb-5"
                  variant="secondary"
                  text="Cancel"
                  type="button"
                  uppercase
                  fullWidth
                  outline
                  isLoading={reset.isSubmitting}
                  disabled={reset.isSubmitting}
                  onClick={onCancel}
                />
              </Col>
              <Col xs={{ span: 12, order: 1 }} md={{ span: 8, order: 2 }}>
                <Button
                  className="mb-5"
                  variant="primary"
                  text="Reset password"
                  type="submit"
                  uppercase
                  fullWidth
                  isLoading={reset.isSubmitting}
                  disabled={reset.isSubmitting}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

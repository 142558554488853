import React, { useState, useEffect, useRef } from 'react';
import { AddRemove, Input } from '@webfx/core-web';

const EditableCell = ({
  value: initialValue,
  row,
  column,
  // these get passed in through the table props
  updateToken,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const ref = useRef();

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onBlur = () => {
    if (value === initialValue) {
      return;
    }

    updateToken({
      values: {
        ...row.original,
        [column.id]: value,
      },
      field: column.id,
    });
  };

  const onKeyDown = (event) => {
    // escape key
    if (event.keyCode === 27) {
      setValue(initialValue);
      if (ref.current) {
        ref.current.blur();
      }
    }
    if (event.keyCode === 13) {
      if (ref.current) {
        ref.current.blur();
      }
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Input
      ref={ref}
      name={`${column.id}-${row.index}`}
      value={value}
      onKeyDown={onKeyDown}
      onChange={onChange}
      onBlur={onBlur}
      className="mb-0"
      inlineEdit
      placeholder={column.id === 'description' ? 'What is this key used for?' : ''}
    />
  );
};

const RemoveCell = ({
  row,
  // gets passed in through the table props
  createToken,
  removeToken,
}) => {
  if (!row.original.tokenId) {
    return null;
  }

  return (
    <AddRemove
      onAdd={createToken}
      onRemove={() => {
        removeToken({ id: row.original.tokenId });
      }}
    />
  );
};

const columns = [
  {
    Header: 'Key',
    accessor: 'token',
    customProps: {
      width: 360,
    },
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: EditableCell,
  },
  // todo add expires
  // todo add role assignment
  {
    id: 'remove',
    customProps: {
      width: 100,
      className: 'text-center',
    },
    Cell: RemoveCell,
  },
];
export default columns;

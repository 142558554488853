const { DateTime } = require('luxon');

/**
 * Check if the report was created in the current month
 * @param {object} report
 * @returns {boolean}
 */
function isCurrentReport(report) {
  if (!report?.createdAt) {
    return false;
  }
  const startOfCurrentMonth = DateTime.utc().startOf('month');
  const reportCreatedAt =
    typeof report.createdAt === 'object'
      ? DateTime.fromJSDate(report.createdAt).toUTC()
      : DateTime.fromISO(report.createdAt).toUTC();
  if (reportCreatedAt >= startOfCurrentMonth) {
    return true;
  }
  return false;
}

module.exports = isCurrentReport;

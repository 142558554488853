import { api } from '@webfx/core-web';

const useHelpArticles = ({ query, fromSearch = false }) => {
  return api.useQuery(
    [
      'help-articles',
      {
        ...query,
      },
    ],
    {
      staleTime: 1000 * 60 * 15, // 15 min
      cacheTime: 1000 * 60 * 30, // 30 min
      placeholderData: [],
      select: (data) => data ?? [],
      enabled: fromSearch ? !!query.searchTerms : true,
    }
  );
};

export default useHelpArticles;

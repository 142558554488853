import { Spinner } from '@webfx/core-web';
import useHelpCategories from '@webfx/common-web/src/hooks/help-center/useHelpCategories';

import CategoryCard from '../CategoryCard';

import styles from './CategoryList.module.css';

const CategoryList = () => {
  const { data: categories, isFetching } = useHelpCategories();

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center py-2">
        <Spinner small />
      </div>
    );
  }

  return (
    <>
      <h3 className="text-gray-550 bold font-24 mb-3" data-fx-name="label">
        Categories
      </h3>

      <div className={styles.categoryList}>
        {categories.map((category) => (
          <CategoryCard key={category.id} category={category} />
        ))}
      </div>
    </>
  );
};

export default CategoryList;

import { string, object } from 'yup';

const phoneRegexp = /^[0-9( )-]+$/;
const onlyNumberRegexp = /^[0-9]*$/;
const onlyLettersAndSymbols = /^[ A-Za-z!$%^*()_@./'#&=+-]*$/;

export {
  editPassword,
  editPasswordConfirmation,
  createPassword,
  confirmPassword,
  password,
} from '@webfx/common-web/src/utils/validators';

export const errors = {
  required: 'Field is required',
  user: {
    firstName: 'First name must be no longer than 30 characters',
    lastName: 'Last name must be no longer than 50 characters',
    title: 'Title must be no longer than 100 characters',
    office: 'Office number must be no longer than 20 characters',
    phone: 'Phone number must be no longer than 20 characters',
    ext: 'Extension must be no longer than 10 characters',
  },
  company: {
    title: 'Title must be no longer than 100 characters',
    address1: 'Address 1 must be no longer than 255 characters',
    address2: 'Address 2 must be no longer than 255 characters',
    city: 'City must be no longer than 50 characters',
    state: 'State must be no longer than 50 characters',
    zip: 'ZIP/Postal Code must be no longer than 20 characters',
    webAddress: 'Web address must be no longer than 150 characters',
    office: 'Office number must be no longer than 20 characters',
  },
  email: {
    incorrect: 'Email address must be valid (format: john@doe.com)',
    length: 'Email Address must be no longer than 100 characters',
  },
  oldPassword: {
    confirm: 'Old password is required',
  },
  projectName: {
    required: 'Project name cannot be empty',
    max: 'Project name must be no longer than 100 characters',
  },
  message: {
    title: 'Title must be no longer than 255 characters',
  },
  onlyLetters: 'Can contain only letters',
  onlyLettersAndSymbols: 'Can contain only letters and special symbols',
  onlyNumbersAndSymbols: 'Can contain only numbers, spaces and symbols (, ), -',
  onlyNumbers: 'Can contain only numbers',
  todoList: {
    name: 'List name must be no longer than 100 characters',
    required: 'Name cannot be empty',
  },
  milestoneTitle: {
    required: 'Field is required',
    max: 'Title must be no longer than 100 characters',
  },
  milestoneDueAt: {
    required: 'Field is required',
  },
};

export const email = string()
  .email(errors.email.incorrect)
  .required(errors.required)
  .max(100, errors.email.length);

export const notificationEmail = string()
  .email(errors.email.incorrect)
  .max(100, errors.email.length)
  .nullable();

export const newEmail = string().email(errors.email.incorrect).max(100, errors.email.length);

export const addOldPassword = string().when('password', {
  is: (val) => val,
  then: string().required(errors.oldPassword.confirm),
});

export const required = string().trim().required(errors.required);

export const projectName = string()
  .required(errors.projectName.required)
  .max(100, errors.projectName.max);

export const milestoneTitle = string()
  .required(errors.milestoneTitle.required)
  .max(100, errors.milestoneTitle.max);

export const milestoneItem = object().shape({
  title: milestoneTitle,
  dueAt: string().required(errors.required),
  assigneeId: string().required(errors.required),
});

export const firstName = string()
  .required(errors.required)
  .max(30, errors.user.firstName)
  .matches(onlyLettersAndSymbols, errors.onlyLettersAndSymbols);

export const lastName = string()
  .required(errors.required)
  .max(50, errors.user.lastName)
  .matches(onlyLettersAndSymbols, errors.onlyLettersAndSymbols);

export const title = string().max(100, errors.user.title).nullable();

export const officeNumber = string()
  .max(20, errors.user.office)
  .matches(phoneRegexp, errors.onlyNumbersAndSymbols)
  .nullable();

export const ext = string()
  .max(10, errors.user.ext)
  .matches(onlyNumberRegexp, errors.onlyNumbers)
  .nullable();

export const mobileNumber = string()
  .max(20, errors.user.phone)
  .matches(phoneRegexp, errors.onlyNumbersAndSymbols)
  .nullable();

export const homeNumber = string()
  .max(20, errors.user.homeNumber)
  .matches(phoneRegexp, errors.onlyNumbersAndSymbols);

export const textEditorContent = string().test(
  'is editor empty',
  errors.required,
  (value) => value && !['', '<p><br></p>'].includes(value)
);

export const companyTitle = string().required(errors.required).max(100, errors.company.title);

export const companyAddress1 = string().max(255, errors.company.address1);

export const companyAddress2 = string().max(255, errors.company.address2);

export const companyCity = string()
  .max(50, errors.company.city)
  .matches(onlyLettersAndSymbols, errors.onlyLettersAndSymbols);

export const companyState = string()
  .max(50, errors.company.state)
  .matches(onlyLettersAndSymbols, errors.onlyLettersAndSymbols);

export const companyZip = string().max(20, errors.company.zip);

export const companyWebAddress = string().max(150, errors.company.webAddress);

export const companyOffice = string()
  .max(20, errors.company.office)
  .matches(phoneRegexp, errors.onlyNumbersAndSymbols);

export const messageTitle = string().required(errors.required).max(255, errors.message.title);

export const todoListName = string()
  .max(100, errors.todoList.name)
  .required(errors.todoList.required);

import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Svg, Spinner } from '@webfx/core-web';
import classnames from 'classnames';
import HelpIcon from '../../../assets/images/help.svg';
import HelpArticle from './HelpArticle';

import styles from './HelpCenterWidget.module.css';

const HelpCenterWidget = ({
  title = null,
  showBorder = true,
  showDefaultHeader = true,
  showDefaultFooter = false,
  wrapperClass,
  headerComponent: HeaderComponent = null,
  footerComponent: FooterComponent = null,
  customArticles = null,
  condensed = false,
  loading = false,
  useVideoIcons = false,
}) => {
  //query to fetch article stats from external api
  const { isLoading: isFetchingTopArticles, data: topArticlesData } = useQuery(
    'fetchTopArticles',
    () => {
      return fetch(
        'https://api.helpdocs.io/v1/stats/article?key=nlij69wk0qmgpr8q8nl67vrh1vxekmiznrjjfu6j'
      ).then((res) => res.json());
    },
    {
      enabled: !customArticles,
    }
  );

  //query to fetch articles from external api
  const { isLoading: isFetchingArticles, data: articlesData } = useQuery(
    'fetchArticles',
    () => {
      return fetch(
        'https://api.helpdocs.io/v1/article?key=nlij69wk0qmgpr8q8nl67vrh1vxekmiznrjjfu6j'
      ).then((res) => res.json());
    },
    {
      enabled: !customArticles,
    }
  );

  //filter and return top articles based on top 10 stats
  const defaultArticles = useMemo(() => {
    if (topArticlesData && articlesData) {
      const topArticles = topArticlesData.top_articles.slice(0, 9);
      const allArticles = articlesData.articles;

      return allArticles.filter((article) =>
        topArticles.some((topItem) => topItem.article_id === article.article_id)
      );
    }

    return [];
  }, [articlesData, topArticlesData]);

  // show customArticles if its not falsy otherwise show default
  const helpArticles = customArticles ?? defaultArticles;

  const isLoading = isFetchingTopArticles || isFetchingArticles || loading;
  return (
    <div className={classnames(styles.wrapper, showBorder && styles.border, wrapperClass)}>
      {/* show default header */}
      {!HeaderComponent && showDefaultHeader && (
        <div className={classnames(styles.header)}>
          <div className={classnames('row mb-3')}>
            <div className={classnames('col bold font-14 d-flex align-items-center', styles.title)}>
              <Svg src={HelpIcon} className={styles.helpIcon} />{' '}
              <div>{title ?? 'Client Knowledge Center'}</div>
            </div>
            <div className={classnames('col bold font-14 text-right')}>
              <a
                className={styles.link}
                href={`${window.location.origin}/help-center`}
                target="_blank"
                rel="noreferrer"
              >
                Visit Client Knowledge Center
              </a>
            </div>
          </div>
          <div className={classnames(styles.desc, 'font-12')}>
            Use our WebFX Client Knowledge Center to find answers to your questions and explore
            exclusive content from our Digital Marketing & MarketingCloudFX experts
          </div>
        </div>
      )}
      {/* show custom header component */}
      {HeaderComponent && <HeaderComponent />}

      {/* show article items */}
      <div className={classnames(condensed ? 'p-0' : 'mt-3', styles.articlesWrapper)}>
        {isLoading ? (
          <Spinner />
        ) : (
          helpArticles.map((item) => (
            <div
              className={condensed ? 'mb-2' : 'mb-3'}
              key={item.article_id}
              data-fx-name="helpDocsTitle"
            >
              <HelpArticle article={item} useVideoIcon={useVideoIcons} />
            </div>
          ))
        )}
      </div>

      {/* show default footer */}
      {showDefaultFooter && !FooterComponent && (
        <div
          className={classnames(
            styles.footer,
            'd-flex justify-content-center align-items-center bold font-14 mt-4'
          )}
        >
          <a
            className={styles.footerLink}
            href={`${window.location.origin}/help-center`}
            target="_blank"
            rel="noreferrer"
          >
            Visit Client Knowledge Center
          </a>
        </div>
      )}

      {/* show custom footer */}
      {FooterComponent && <FooterComponent />}
    </div>
  );
};

export default HelpCenterWidget;

import { store } from '@webfx/core-web';

// OPFX client request url
export const OPFXRequestUrl = 'https://operationsfx.com/request/asset';

// Register all
store.dispatch.router.registerRoutes([
  // GENERIC routes - [Overview, PDFRenderer, Settings]
  {
    name: 'csfxHome',
    url: '/competitorspy',
  },
  {
    name: 'csfxOverview',
    url: '/competitorspy/:siteId?/overview',
  },
  {
    name: 'csfxPDFRenderer',
    url: '/competitorspy/:siteId?/:page?/pdf/:date?',
  },
  {
    name: 'csfxSettings',
    url: '/competitorspy/:siteId?/settings',
  },

  // SEO routes  - [Traffic, Content, Links, Keywords]
  {
    name: 'csfxSEOTraffic',
    url: '/competitorspy/:siteId?/seo-traffic',
  },
  {
    name: 'csfxSEOContent',
    url: '/competitorspy/:siteId?/seo-content',
  },
  {
    name: 'csfxSEOLinks',
    url: '/competitorspy/:siteId?/seo-links',
  },
  {
    name: 'csfxSEOKeywords',
    url: '/competitorspy/:siteId?/seo-keywords',
  },

  // PPC routes - [Traffic, Keywords, Ads]
  {
    name: 'csfxPPCTraffic',
    url: '/competitorspy/:siteId?/ppc-traffic',
  },
  {
    name: 'csfxPPCKeywords',
    url: '/competitorspy/:siteId?/ppc-keywords',
  },
  {
    name: 'csfxPPCAds',
    url: '/competitorspy/:siteId?/ppc-ads',
  },

  // Other routes - [Social, Keywords, Ads]
  {
    name: 'csfxOtherSocial',
    url: '/competitorspy/:siteId?/social',
  },
  {
    name: 'csfxOtherEngagement',
    url: '/competitorspy/:siteId?/engagement',
  },
  {
    name: 'csfxOtherLeads',
    url: '/competitorspy/:siteId?/leads',
  },
  {
    name: 'csfxOtherPageSpeed',
    url: '/competitorspy/:siteId?/page-speed',
  },
]);

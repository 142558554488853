import React from 'react';
import classnames from 'classnames';
import { Svg, Typography, Button } from '@webfx/core-web';
import { SiteSelector } from '@webfx/web-ui-connected';
import { useLayout, useRouter } from '@webfx/web-hooks';
import { ProductLogo } from '@webfx/web-ui';

import styles from './ProductBanner.module.css';

const ProductBanner = () => {
  const { route, logo, copy, hideSelector } = useLayout();
  const { active } = useRouter();

  if ((!logo && !copy) || !active) {
    return null;
  }
  return (
    <div
      id="hocPageBanner"
      className={classnames(styles.wrapper, styles[`wrapper-${route || active}`])}
    >
      <CopyBlock copy={copy} logo={logo} />
      <div className={classnames(styles.graphicBlock, styles.background, styles[route || active])}>
        {!hideSelector ? (
          <div className={styles.selectorWrapper}>
            <SiteSelector />
          </div>
        ) : null}
      </div>
    </div>
  );
};

function CopyBlock({ logo, copy }) {
  const TitleComponent = copy.TitleComponent;
  return (
    <div className={classnames(styles.copyBlock)}>
      {typeof logo === 'string' ? (
        <ProductLogo product={logo} className={styles.logo} height="43px" />
      ) : (
        <Svg src={logo} svgClassName={styles.logo} data-fx-name="imageContainer" />
      )}
      <div className={classnames(styles.copyContainer)}>
        <div
          className={classnames(styles.description, { [styles.withButton]: !!copy?.onButtonClick })}
        >
          {TitleComponent ? <TitleComponent /> : null}
          <Typography variant="h3" component="h3" className="mb-1" bold>
            {copy.title}
          </Typography>
          {copy.description}
          <span className={styles.moreText}> {copy?.extendedDescription}</span>
        </div>
        {copy?.onButtonClick ? (
          <Button
            outline={false}
            className={styles.bannerButton}
            centerContent
            variant="success"
            text={copy.buttonText || 'See How It Works!'}
            uppercase={false}
            size="xs"
            icon="arrow_forward"
            iconTrailing={true}
            iconOutlined={false}
            iconPosition="right"
            onClick={copy?.onButtonClick}
          />
        ) : null}
      </div>
    </div>
  );
}

export default ProductBanner;

// TODO replace usage with mroi util
// TODO remove this and move icon/label matching to UI utils

const isEmpty = require('lodash/isEmpty');
/**
 * Assists with the retrieval of MROI Channels
 */

/**
 * Standard MROI Channels
 */
const channelMappedData = {
  seoOrganic: {
    channels: [{ medium: 'organic' }],
    icon: 'seoOrganic',
    label: 'Search - SEO/Organic',
  },
  localFX: {
    channels: [
      { medium: 'localfx', source: 'organic', campaign: { in: ['localfx'] } },
      { medium: 'local' },
      { medium: 'google', source: 'organic', campaign: { in: ['gmb'] } },
      { medium: 'bing', source: 'organic', campaign: { in: ['bing-places'] } },
      { campaign: { in: ['local'] } },
    ],
    icon: 'localFx',
    label: 'Search - LocalFX/Maps',
  },
  paidSearch: {
    channels: [{ medium: 'ppc' }, { medium: 'cpc' }],
    icon: 'paidSearch',
    label: 'Search - Paid',
  },
  socialOrganic: {
    channels: [
      { medium: 'social', campaign: { in: ['fb-organic', 'li-organic', 'ig-organic', undefined] } },
    ],
    icon: 'socialOrganic',
    label: 'Social - Organic',
  },
  device: {
    channels: [
      { medium: 'search' },
      { medium: 'direct' },
      { medium: 'internal' },
      { medium: 'none' },
      { medium: 'all' },
    ],
    icon: 'systemDevice',
    label: 'Other - Cookies Blocked/Direct/Encrypted/Dark Attribution',
  },
  socialPaid: {
    channels: [
      { medium: 'paid', campaign: { exclude: ['fb-organic', 'li-organic', 'ig-organic'] } },
      { medium: 'social', campaign: { exclude: ['fb-organic', 'li-organic', 'ig-organic'] } },
      { medium: 'paid-social' },
    ],
    icon: 'socialPaid',
    label: 'Social - Paid',
  },
  targetedAd: {
    channels: [{ medium: 'cpc', source: 'geofencing' }],
    icon: 'targetedAds',
    label: 'AdTechFX - Rented Audience Data Programmatic Advertising',
  },
  leadNurture: {
    channels: [
      { medium: 'email' }, // anything with medium='email'
    ],
    icon: 'leadNurture',
    label: 'Email - EmailMarketingFX/Lead Nurture/SMS Sequences',
  },
  referring: {
    channels: [{ medium: 'referral', source: undefined }],
    icon: 'referringWebsites',
    label: 'Referrals - Websites/Earned Media',
  },
  crmRetargeting: {
    channels: [
      { medium: 'cpc', source: 'adtechfx-crm' },
      { medium: 'cpc', source: 'adtechfx-abm' },
    ],
    icon: 'crmRetargeting',
    label: 'AdTechFX - Enriched 1st Party Audience Data Programmatic Advertising',
  },
  traditionalAd: {
    channels: [],
    icon: 'traditionalAd',
    label: 'Traditional (used for MROI comparison)',
  },
};

const appModules = {
  companyTracker: { source: 'fvs', medium: 'fvm', campaign: 'campaign' },
  leadManager: { source: 'source', medium: 'medium', campaign: 'campaign' },
  callTracker: { source: '', medium: 'medium', campaign: 'campaign' },
  cpl: { source: '', medium: '' },
};

/**
 * Retrives MROI iconObj from the original channel and module used.
 * @param {object} originalChannel
 * @param {*} moduleUsed
 * @returns iconObj
 */
function getMroiChannel(originalChannel = {}, moduleUsed = '') {
  if (isEmpty(originalChannel) && isEmpty(moduleUsed)) {
    return { mappedData: channelMappedData, modules: appModules };
  }

  const iconObj = { found: false, icon: '', label: '' };

  if (!isEmpty(originalChannel) && !isEmpty(moduleUsed)) {
    Object.keys(channelMappedData).map((value) => {
      const channels = channelMappedData[value];
      const icon = channels['icon'];
      const label = channels['label'];

      if (!iconObj.found) {
        channels['channels'].map((channel) => {
          if (
            originalChannel &&
            (((!originalChannel[appModules[moduleUsed].source] ||
              originalChannel[appModules[moduleUsed].source] === channel.source ||
              !channel.source) &&
              originalChannel[appModules[moduleUsed].medium] === channel.medium &&
              (!originalChannel[appModules[moduleUsed].campaign] ||
                !channel.campaign ||
                (originalChannel[appModules[moduleUsed].campaign] &&
                  channel.campaign &&
                  ((channel.campaign.exclude &&
                    !originalChannel[appModules[moduleUsed].campaign].includes(
                      channel.campaign.exclude
                    )) ||
                    (channel.campaign.in &&
                      originalChannel[appModules[moduleUsed].campaign].includes(
                        channel.campaign.in
                      )))))) ||
              (!channel.source &&
                !channel.medium &&
                channel.campaign.in &&
                originalChannel[appModules[moduleUsed].campaign] &&
                originalChannel[appModules[moduleUsed].campaign].includes(channel.campaign.in)))
          ) {
            iconObj.found = true;
            iconObj.icon = icon;
            iconObj.label = label;
          }
        });
      }
    });
  }

  return iconObj;
}

module.exports = getMroiChannel;

import classnames from 'classnames';

import { Icon, Avatar, Link } from '@webfx/core-web';
import { useRouter, useAuth } from '@webfx/web-hooks';

import {
  buildArticleUrl,
  formatDate,
} from '@webfx/common-web/src/ui/pages/HelpCenter/utils/utils.js';

import styles from './ResultCard.module.css';

const ResultCard = ({ article }) => {
  const { isGuest } = useAuth();

  const { url } = useRouter();
  const person = article.lastModifiedBy;

  const name =
    person?.firstName && person?.lastName ? `${person.firstName} ${person.lastName}` : person.email;

  const date = formatDate(article.lastModified);

  return (
    <Link to={buildArticleUrl({ url, article })} className="text-gray-700 m-0" plain={!isGuest}>
      <div className="d-flex flex-column border-bottom mb-3">
        <div className={'d-flex flex-column font-20 text-blue-vivid-500'}>
          <span
            className={classnames(styles.name, 'bold pb-2')}
            {...(article.highlightedTitleContent
              ? {
                  dangerouslySetInnerHTML: {
                    __html: article.highlightedTitleContent,
                  },
                }
              : { children: article.name })}
          />

          <div className="d-flex align-items-center text-gray-500 font-14">
            <Avatar src={person?.image} className={classnames(styles.avatar, 'mr-2')} />
            <span>{name}</span>
            <Icon className="font-18 ml-1">update</Icon>
            <span className="ml-1">{`Updated on ${date}`}</span>
          </div>
        </div>
        <div
          className={classnames(styles.content, 'py-2 text-gray-700 font-14')}
          dangerouslySetInnerHTML={{ __html: article.highlightedBodyContent }}
        />
      </div>
    </Link>
  );
};

export default ResultCard;

import { api, ContentContainer, Paper, Spinner } from '@webfx/core-web';
import { useAuth } from '@webfx/web-hooks';
import React, { useEffect, useState } from 'react';
import styles from './HelpCenter.module.css';

const HelpCenter = () => {
  const user = useAuth().user;
  const settings = api.useQuery([
    'user-settings',
    {
      userId: user.userId,
      field: 'helpdocs',
    },
  ]);

  const [helpDocsLoginUrl, setHelpDocsLoginUrl] = useState(null);
  const [helpDocsDomainUrl, setHelpDocsDomainUrl] = useState(null);
  const setHelpDocsUrl = (iframeUrl, domainUrl) => {
    setHelpDocsLoginUrl(iframeUrl);
    setHelpDocsDomainUrl(domainUrl);
  };

  useEffect(() => {
    const helpDocSetting = settings.data?.data?.[0];
    if (!helpDocSetting) {
      return;
    }

    // check url path for any article references
    const article = window.location.pathname.replace('/help-center', '');
    let iframeUrl = helpDocSetting.tokenUrl + '?hd_jwt=' + helpDocSetting.token;
    if (!article) {
      setHelpDocsUrl(iframeUrl, helpDocSetting.helpDocsDomain);
    } else {
      iframeUrl =
        helpDocSetting.helpDocsDomain + '/article' + article + '?hd_jwt=' + helpDocSetting.token;
      setHelpDocsUrl(iframeUrl, helpDocSetting.helpDocsDomain);
    }

    // allow iframe to post messages to parent to return the current url
    const handleMessage = (event) => {
      if (event.origin === helpDocSetting.helpDocsDomain) {
        event.source.postMessage(
          { message: 'parent-origin', value: window.location.origin },
          event.origin
        );
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [settings.data]);

  if (settings.isLoading || !helpDocsLoginUrl || !helpDocsDomainUrl) {
    return <Spinner />;
  }

  return (
    <ContentContainer>
      <Paper>
        <div className={styles.wrap}>
          <iframe
            src={helpDocsLoginUrl}
            className={styles.frame}
            frameBorder="0"
            allow="clipboard-read; clipboard-write"
          />
        </div>
      </Paper>
    </ContentContainer>
  );
};

export default HelpCenter;

const industriesJSON = require('./industries.json');

// Arts, Entertainment, and Recreation
const blockedArtsEntertainment = [
  '71',
  '711',
  '7111',
  '71111',
  '711110',
  '71112',
  '711120',
  '71113',
  '711130',
  '71119',
  '711190',
  '7112',
  '71121',
  '711211',
  '711212',
  '711219',
  '7113',
  '71131',
  '711310',
  '71132',
  '711320',
  '7114',
  '71141',
  '711410',
  '7115',
  '71151',
  '711510',
  '712',
  '7121',
  '71211',
  '712110',
  '71212',
  '712120',
  '71213',
  '712130',
  '71219',
  '712190',
  '713',
  '7131',
  '71311',
  '713110',
  '71312',
  '713120',
  '7132',
  '71321',
  '713210',
  '71329',
  '713290',
  '7139',
  '71391',
  '713910',
  '71392',
  '713920',
  '71393',
  '713930',
  '71394',
  '713940',
  '71395',
  '713950',
  '71399',
  '713990',
];

// Accommodation and Food Services
const blockedAccommodation = [
  '72',
  '721',
  '7211',
  '72111',
  '721110',
  '72112',
  '721120',
  '72119',
  '721191',
  '721199',
  '7212',
  '72121',
  '721211',
  '721214',
  '7213',
  '72131',
  '721310',
  '722',
  '7223',
  '72231',
  '722310',
  '72232',
  '722320',
  '72233',
  '722330',
  '7224',
  '72241',
  '722410',
  '7225',
  '72251',
  '722511',
  '722513',
  '722514',
  '722515',
];

// Information
const blockedInformation = [
  '51',
  '511',
  '5111',
  '51111',
  '511110',
  '51112',
  '511120',
  '51113',
  '511130',
  '51114',
  '511140',
  '51119',
  '511191',
  '511199',
  '5112',
  '51121',
  '511210',
  '512',
  '5121',
  '51211',
  '512110',
  '51212',
  '512120',
  '51213',
  '512131',
  '512132',
  '51219',
  '512191',
  '512199',
  '5122',
  '51223',
  '512230',
  '51224',
  '512240',
  '51225',
  '512250',
  '51229',
  '512290',
  '515',
  '5151',
  '51511',
  '515111',
  '515112',
  '51512',
  '515120',
  '5152',
  '51521',
  '515210',
  '517',
  '5173',
  '51731',
  '517311',
  '517312',
  '5174',
  '51741',
  '517410',
  '5179',
  '51791',
  '517911',
  '517919',
  '518',
  '5182',
  '51821',
  '518210',
  '519',
  '5191',
  '51911',
  '519110',
  '51912',
  '519120',
  '51913',
  '519130',
  '51919',
  '519190',
];

// Professional, Scientific, and Technical Services
const blockedProfessional = [
  '541519',
  '5419',
  '54191',
  '541910',
  '54192',
  '541921',
  '541922',
  '54193',
  '541930',
  '54194',
  '541940',
  '54199',
  '541990',
];

const blocked = [
  '33421',
  '56143',
  ...blockedArtsEntertainment,
  ...blockedAccommodation,
  ...blockedInformation,
  ...blockedProfessional,
];

const mcfxBlockedIndustries = [];

function addToBlocklist(id, industries = null) {
  industries = !industries ? industriesJSON : industries;
  for (const item of industries) {
    if (id === item.id) {
      mcfxBlockedIndustries.push({ id: item.id, name: item.text });
    } else {
      if (item.children) {
        addToBlocklist(id, item.children);
      }
    }
  }
}

blocked.forEach((id) => addToBlocklist(id));

module.exports = mcfxBlockedIndustries;

/**
 * Cast numeric, boolean, and null values in Express req.query object to JS types.
 *
 * Forked from: https://github.com/xpepermint/query-types
 * Changes:
 *  - Dont discard key-value pair if value is empty string
 */

function isObject(val) {
  return typeof val === 'object' && val !== null;
}

function isNumber(val) {
  // Calltracking Number
  if (/^\+(\d{1,2})\s?(\d{3})\d{3}\d{4}$/.exec(val)) {
    return false;
  }

  return !isNaN(parseFloat(val)) && isFinite(val);
}

function isBoolean(val) {
  return val === 'false' || val === 'true';
}

function isArray(val) {
  return Array.isArray(val);
}

function parseValue(val) {
  if (typeof val === 'undefined') {
    return null;
  }

  if (val === '') {
    return val;
  }

  if (isBoolean(val)) {
    return parseBoolean(val);
  }

  if (isArray(val)) {
    return parseArray(val);
  }

  if (isObject(val)) {
    return castObjectDeep(val);
  }

  if (isNumber(val)) {
    return parseNumber(val);
  }

  return val;
}

function parseArray(arr) {
  const result = [];
  for (let i = 0; i < arr.length; i++) {
    result[i] = parseValue(arr[i]);
  }
  return result;
}

function parseNumber(val) {
  return Number(val);
}

function parseBoolean(val) {
  return val === 'true';
}

function castObjectDeep(obj) {
  return Object.fromEntries(Object.entries(obj ?? {}).map(([k, v]) => [k, parseValue(v)]));
}

module.exports = castObjectDeep;

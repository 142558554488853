import { useState, useEffect, useCallback } from 'react';

const useHeaderTracking = (contentElement, articleContent) => {
  const [headers, setHeaders] = useState([]);

  const detectHeaders = useCallback(() => {
    if (contentElement) {
      const headerElements = contentElement.querySelectorAll('h1, h2, h3');
      const extractedHeaders = Array.from(headerElements)
        .filter((header) => header.textContent.trim() !== '')
        .map((header, index) => {
          if (!header.id) {
            header.id = `header-${index}`;
          }
          return {
            id: header.id,
            text: header.textContent,
            level: parseInt(header.tagName.charAt(1)),
          };
        });

      setHeaders(extractedHeaders);
      return headerElements;
    }
    return [];
  }, [contentElement]);

  // Re-run header detection when content changes
  useEffect(() => {
    if (contentElement && articleContent) {
      const observer = new MutationObserver(() => {
        detectHeaders();
      });

      observer.observe(contentElement, { childList: true, subtree: true });

      // Initial detection
      detectHeaders();

      return () => observer.disconnect();
    }
  }, [detectHeaders, contentElement, articleContent]);

  const scrollToHeader = useCallback(
    (id) => {
      const element = contentElement?.querySelector(`#${id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    [contentElement]
  );

  return { headers, scrollToHeader };
};

export default useHeaderTracking;

import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Dropdown } from 'react-bootstrap';

import { IconLabel, Input } from '@webfx/core-web';
import { useRouter } from '@webfx/web-hooks';

import useHelpSuggestions from '@webfx/common-web/src/hooks/help-center/useHelpSuggestions';

import { buildArticleUrl } from '@webfx/common-web/src/ui/pages/HelpCenter/utils/utils.js';

import styles from './SearchBar.module.css';

const SearchBar = () => {
  const { url } = useRouter();
  const history = useHistory();
  const location = useLocation();
  const [query, setQuery] = useState('');
  const { suggestions } = useHelpSuggestions(query);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (!queryParams.has('query')) {
      setQuery('');
    }
  }, [location]);

  const handleKeyDown = (e) => {
    const searchQuery = e.target.value || query;
    if (e.key === 'Enter') {
      history.push(`/help-center/search?query=${searchQuery}`);
      setShowDropdown(false);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setShowDropdown(e.target.value.length > 0);
  };

  const handleSuggestionClick = (article) => {
    setQuery('');
    setShowDropdown(false);
    history.push(buildArticleUrl({ url, article }));
  };

  return (
    <div className={styles.searchContainer}>
      <Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
        <Dropdown.Toggle as={CustomToggle}>
          <Input
            ref={inputRef}
            placeholder="Search Client Knowledge Center"
            className={styles.searchBar}
            icon="search"
            iconPosition="left"
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            value={query}
            clearable={true}
            onClear={() => setQuery('')}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className={styles.suggestionsList}>
          {query && (
            <>
              <Dropdown.Item
                className="d-flex justify-content-between py-1"
                onKeyDown={handleKeyDown}
                onClick={() => history.push(`/help-center/search?query=${query}`)}
              >
                <div>
                  <span className="bold">{query}</span> - show all results
                </div>

                <IconLabel
                  icon={'keyboard_return'}
                  label="ENTER"
                  className="d-flex align-items-center"
                  labelClassName="mr-1"
                  iconPosition="right"
                />
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}

          {suggestions.length > 0 ? (
            <>
              <Dropdown.Header>Suggested Results</Dropdown.Header>
              {suggestions.slice(0, 5).map((suggestion, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className={styles.suggestionItem}
                >
                  {suggestion.name}
                </Dropdown.Item>
              ))}
            </>
          ) : (
            <Dropdown.Item disabled>No suggested results</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export default SearchBar;

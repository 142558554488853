/*
 * Hardcoded data and compare funcs for matching sites (based on site subscriptions)
 * and reports (based on report names) to WebFX services.
 *
 * Order matters, as the first match will be used - so more specific matches should come first.
 *
 * Display order on the Analytics frontend is determined by the displayOrder property.
 */
const reportServiceData = [
  {
    name: 'Amazon/Marketplace',
    searchTerms: ['amazon', 'marketplace'],
    subscriptionMatch: (subscription) =>
      subscription.className?.toLowerCase().includes('marketplace'),
    reportNameMatch: (name) => hasMatch(name, ['amazon', 'marketplace']),
    displayOrder: 8,
  },
  {
    name: 'AdTech/Geofencing',
    searchTerms: ['adtech', 'geofencing', 'programmatic'],
    subscriptionMatch: (subscription) =>
      subscription.itemName?.toLowerCase().includes('adtech') ||
      subscription.groupName?.toLowerCase().includes('geofencing'),
    reportNameMatch: (name) => hasMatch(name, ['adtech', 'geofencing', 'programmatic']),
    displayOrder: 6,
  },
  {
    name: 'Local SEO',
    searchTerms: ['local'],
    subscriptionMatch: (subscription) => subscription.groupName?.toLowerCase().includes('local'),
    reportNameMatch: (name) => hasMatch(name, ['local']),
    displayOrder: 5,
  },
  {
    name: 'SEO',
    searchTerms: ['seo', 'search engine optimization'],
    subscriptionMatch: (subscription) => subscription.className?.toLowerCase().includes('seo'),
    reportNameMatch: (name) => hasMatch(name, ['seo', 'search engine optimization']),
    displayOrder: 1,
  },
  {
    name: 'PPC',
    searchTerms: ['ppc', 'paid search'],
    subscriptionMatch: (subscription) => subscription.className?.toLowerCase().includes('ppc'),
    reportNameMatch: (name) => hasMatch(name, ['ppc', 'paid search']),
    displayOrder: 2,
  },
  {
    name: 'Social',
    searchTerms: ['social'],
    subscriptionMatch: (subscription) => subscription.className?.toLowerCase().includes('social'),
    reportNameMatch: (name) => hasMatch(name, ['social']),
    displayOrder: 3,
  },
  {
    name: 'CRO',
    searchTerms: ['cro', 'conversion rate optimization'],
    subscriptionMatch: (subscription) => subscription.className?.toLowerCase().includes('cro'),
    reportNameMatch: (name) => hasMatch(name, ['cro', 'conversion rate optimization']),
    displayOrder: 4,
  },
  {
    name: 'Email Marketing',
    searchTerms: ['email', 'nurture'],
    subscriptionMatch: (subscription) => subscription.className?.toLowerCase().includes('email'),
    reportNameMatch: (name) => hasMatch(name, ['email', 'nurture']),
    displayOrder: 7,
  },
];

const hasMatch = (string, searchTerms) =>
  searchTerms.some((term) => string?.toLowerCase().includes(term));

module.exports = reportServiceData;

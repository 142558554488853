import classnames from 'classnames';
import { Icon, Link } from '@webfx/core-web';
import { useAuth } from '@webfx/web-hooks';

import styles from './GuruAdminCTA.module.css';

const GuruAdminCTA = ({ link, edit = false, className }) => {
  const { isAdmin } = useAuth();

  // Only admins can see this CTA
  if (!isAdmin) {
    return null;
  }

  return (
    <Link
      href={link}
      plain={true}
      className={classnames(
        styles.wrapper,
        [{ 'p-2': edit, 'p-4': !edit }],
        'd-flex justify-content-center border rounded text-blue-400 text-hover-blue-500 bg-gray-100 bg-hover-gray-200',
        className
      )}
    >
      <p className="d-flex m-0 align-items-center">
        {edit ? (
          <>
            <Icon className="font-16" outlined>
              edit
            </Icon>
            <span className="ml-2 font-14" data-fx-name="label">
              Edit in Guru
            </span>
            <Icon className="ml-2 text-danger font-14">lock</Icon>
          </>
        ) : (
          <>
            <Icon className="font-16">add_circle</Icon>
            <span className="ml-2 font-18" data-fx-name="label">
              Add new article in Guru
            </span>
            <Icon className="ml-2 text-danger font-16">lock</Icon>
          </>
        )}
      </p>
    </Link>
  );
};

export default GuruAdminCTA;

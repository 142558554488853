const regex = /(\+\d{1,3}\s?)?\(?(\d{3})\)?[\s.-]?(\d{3})[\s.-]?(\d{4})\s?(ext[:.]?|x|X\s?\d+)?/i;
/**
 * Formats phone number as a string with parentheses and dashes
 * @param {String} phoneNumberString
 * @returns {String} phoneNumberString
 */
function phoneNumber(phoneNumberString) {
  const match = phoneNumberString.match(regex);
  if (match) {
    return [match[1], ' (', match[2], ') ', match[3], '-', match[4], ' ', match[5]]
      .filter((seg) => !!seg && seg !== ' ')
      .join('');
  }
  return phoneNumberString;
}

/**
 * Formats phone number as a string with no dashes or parentheses
 * @param {String} phoneNumberString
 * @returns {String} phoneNumberString
 */
function phoneNumberAsTel(phoneNumberString) {
  const match = phoneNumberString.match(regex);
  if (match) {
    return ['tel:', match[1], match[2], match[3], match[4]]
      .filter((seg) => !!seg && seg !== ' ')
      .join('');
  }
  return phoneNumberString;
}

module.exports = {
  phoneNumber,
  phoneNumberAsTel,
};

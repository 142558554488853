const { Duration } = require('luxon');

/**
 * Format seconds into a user friend time string.
 * @param {int} seconds
 * @return {time} time
 */
function userFriendlyTime(seconds) {
  if (seconds === null || seconds === undefined) {
    // eslint-disable-next-line no-param-reassign
    seconds = 0;
  }

  const dur = Duration.fromObject({ seconds });
  const formatted = dur.toFormat('d h m s');
  const parts = formatted.split(' ');
  const keymap = ['day', 'hrs', 'min', 'sec'];

  const time = parts
    .reduce((s, p, i) => {
      if (Number(p) > 0) {
        // eslint-disable-next-line no-param-reassign
        s += `${p} ${keymap[i]} `;
      }
      return s;
    }, '')
    .trim();

  return time === '' ? 'N/A' : time;
}

module.exports = userFriendlyTime;

import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { api } from '@webfx/core-web';
import { email as emailValidator } from '../utils/validators';

const validationSchema = Yup.object().shape({
  email: emailValidator,
});

/**
 * Custom hook for password reset functionality.
 * @returns {Object} An object containing the form, isSubmitting, error, and isError properties.
 */
export function usePasswordReset() {
  const history = useHistory();
  const mutator = api.useMutation(['reset-password'], {
    onSucess: () => {
      history.push({ search: '?password-reset' });
    },
  });

  const form = {
    initialValues: { email: '' },
    validationSchema,
    onSubmit: (values) => {
      mutator.mutate({
        _method: 'create',
        ...values,
      });
    },
  };

  return { form, isSubmitting: mutator.isLoading, error: mutator.error, isError: mutator.isError };
}

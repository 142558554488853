/**
 * Touchpoint Status Options in Spanner
 * Closed Won and Closed Lost differ from mongo
 * @returns {Array} statusOptions
 */
const getTouchpointStatusOptionsSpanner = () => {
  // status options
  const statusOptions = [
    {
      label: 'Uncategorized',
      value: 'uncategorized',
      actualValue: 'uncategorized',
      isActive: false,
      dotColor: '#ABB9C2',
    },
    {
      label: 'Subscription',
      value: 'subscription',
      actualValue: 'subscription',
      isActive: false,
      dotColor: '#FFD12D',
    },
    {
      label: 'New Lead',
      value: 'new_lead',
      actualValue: 'new_lead',
      isActive: false,
      dotColor: '#FFD12D',
    },
    {
      label: 'Lead Follow-Up',
      value: 'followup',
      actualValue: 'followup',
      isActive: false,
      dotColor: '#FFD12D',
    },
    {
      label: 'Spam',
      value: 'spam',
      actualValue: 'spam',
      isActive: false,
      dotColor: '#f44336',
    },
    {
      label: 'Disqualified',
      value: 'disqualified',
      actualValue: 'disqualified',
      isActive: false,
      dotColor: '#F87476',
    },
    {
      label: 'Upsell Request',
      value: 'upsell',
      actualValue: 'upsell',
      isActive: false,
      dotColor: '#B6E684',
    },
    {
      label: 'Downgrade / Cancellation Request',
      value: 'downgrade',
      actualValue: 'downgrade',
      isActive: false,
      dotColor: '#FFA232',
    },
    {
      label: 'Support / Question',
      value: 'support',
      actualValue: 'support',
      isActive: false,
      dotColor: '#84B9F5',
    },
    {
      label: 'Closed Won',
      value: 'closed_won',
      actualValue: 'closed_won',
      isActive: false,
      dotColor: '#41D58C',
    },
    {
      label: 'Closed Lost',
      value: 'closed_lost',
      actualValue: 'closed_lost',
      isActive: false,
      dotColor: '#F87476',
    },
  ];

  return statusOptions;
};

module.exports = getTouchpointStatusOptionsSpanner;

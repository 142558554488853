const ROOT_COLLECTION_ID = 'fd64596d-9613-4d19-90f8-490b4de3437e';

const useHelpBreadcrumbs = (items) => {
  const restructureItem = (item) => {
    return {
      type: item.article ? 'article' : 'category',
      name: item.article?.name || item.title || item.name,
      slug: item.article?.slug || item.slug,
    };
  };

  const restructuredData = items
    .filter((item) => item.id !== ROOT_COLLECTION_ID) // exclude the root collection (Client Knowledge Center)
    .map(restructureItem);

  return restructuredData.reduce((acc, item, index) => {
    if (index === 0) {
      acc.push({ link: '/help-center', name: 'Home' });
    }

    const category =
      item.type === 'article' ? restructuredData.find((i) => i.type === 'category') : item;

    const link =
      '/help-center' +
      (category ? `/category/${category.slug}` : '') +
      (item.type === 'article' ? `/article/${item.slug}` : '');

    acc.push({ link, name: item.name });

    return acc;
  }, []);
};

export default useHelpBreadcrumbs;

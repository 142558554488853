const plansIconEntries = {
  1: 'ancient',
  2: 'lite',
  3: 'foundation',
  4: 'professional',
  5: 'innovator vip',
  6: 'innovator',
};

/**
 * Handles mapping plan-ids to respective image files for plans
 * @param {integer} planId
 * @returns {string} - name of plan image
 */
const getPlanIcon = (planId = 0) => plansIconEntries[planId] ?? null;

module.exports = getPlanIcon;

import { Modal } from 'react-bootstrap';
import { Typography } from '@webfx/core-web';

import GoogleChromeIcon from '../../../assets/images/google-chrome.png';
import MozillaFirefoxIcon from '../../../assets/images/mozilla-firefox-icon.png';
import SafariIcon from '../../../assets/images/safari-icon.png';

import styles from './NotSupportedBrowser.module.css';

const NotSupportedBrowser = () => {
  const ModalHeader = (
    <Modal.Header closeButton={false} className={styles.header}>
      <Modal.Title className={styles.title}>
        We&apos;re sorry, but this browser is not supported
      </Modal.Title>
    </Modal.Header>
  );

  const ModalBody = (
    <section className={styles.body}>
      <Typography>
        To get the best experience we recommended one of the supported browser:
      </Typography>
      <li className={styles.list}>
        <ul className={styles.item}>
          <img
            src={GoogleChromeIcon}
            alt="google chrome browser icon"
            className={styles.browserIcon}
          />
        </ul>
        <ul className={styles.item}>
          <img
            src={MozillaFirefoxIcon}
            alt="mozilla firefox browser icon"
            className={styles.browserIcon}
          />
        </ul>
        <ul className={styles.item}>
          <img src={SafariIcon} alt="safari browser icon" className={styles.browserIcon} />
        </ul>
      </li>
    </section>
  );

  return (
    <Modal show dialogClassName={styles.dialog} onHide={() => null} centered keyboard={false}>
      {ModalHeader}
      {ModalBody}
    </Modal>
  );
};

export default NotSupportedBrowser;

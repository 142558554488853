import { Svg } from '@webfx/core-web/src';

import WebFXLogo from '../../../../assets/images/help-center/webfx-title.svg';

const Footer = () => {
  return (
    <div className="d-flex flex-column" style={{ marginTop: '50px' }}>
      <Svg
        src={WebFXLogo}
        svgStyle={{
          width: '150px',
          filter: 'grayscale(100%) contrast(50%)',
        }}
        className="m-auto mb-4"
      />
      <div className="bg-primary-blue mt-5" style={{ height: '25px' }}></div>
    </div>
  );
};

export default Footer;

/**
 * Checks if string is a phone number.
 *
 * @param obj
 * @returns {boolean}
 */
function isPhoneNumber(value) {
  const regex =
    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g; //eslint-disable-line
  //const regex = /^(\+?\(?[0-9]{1,2}\)?)?[-\s\.]?\(?[0-9]{3}\)?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/; //eslint-disable-line

  if (value === null || value === undefined || value === '') {
    return true;
  }

  if (value.match(regex)) {
    return true;
  }

  return false;
}

module.exports = isPhoneNumber;

'use strict';
const camelCase = require('./camel-case');
const titleCase = require('./title-case');
const userFriendlyTime = require('./user-friendly-time');
const address = require('./address');
const { phoneNumber, phoneNumberAsTel } = require('./phone-number');
const extractCountryRegion = require('./extract-country-region');
const getTagValues = require('./get-tag-values');

module.exports = {
  address,
  camelCase,
  phoneNumber,
  phoneNumberAsTel,
  titleCase,
  userFriendlyTime,
  extractCountryRegion,
  getTagValues,
};

import classnames from 'classnames';

import { useRouter } from '@webfx/web-hooks';
import { Icon, Link } from '@webfx/core-web';
import useHelpCategory from '@webfx/common-web/src/hooks/help-center/useHelpCategory.js';

import { buildArticleUrl } from '@webfx/common-web/src/ui/pages/HelpCenter/utils/utils.js';

const NavigationLink = ({ url, label, articleName }) => {
  const isPrevious = label === 'Previous';

  return (
    <Link
      to={url}
      className={classnames('text-gray-700 text-hover-blue-vivid-400', {
        'ml-auto': !isPrevious,
        'mr-auto': isPrevious,
      })}
    >
      <div className="d-flex align-items-center">
        {isPrevious && <Icon className="font-18">arrow_back</Icon>}

        <div
          className={classnames('d-flex flex-column', {
            'ml-2': isPrevious,
            'mr-2 align-items-end': !isPrevious,
          })}
        >
          <span className="font-14 text-gray-550">{label}</span>
          <span className="font-16">{articleName}</span>
        </div>

        {!isPrevious && <Icon className="font-18">arrow_forward</Icon>}
      </div>
    </Link>
  );
};

const ArticleNavigationLinks = () => {
  const {
    url,
    params: { categoryId, categoryName, articleId },
  } = useRouter();

  const { data } = useHelpCategory();
  const currentArticleIndex = data?.articles?.findIndex(
    (article) => article.slug.split('/')[0] === articleId
  );

  const navigationItems = [
    { article: data?.articles?.[currentArticleIndex - 1], label: 'Previous' },
    { article: data?.articles?.[currentArticleIndex + 1], label: 'Next' },
  ];

  return (
    <div className="d-flex align-items-center">
      {navigationItems.map(
        ({ article, label }) =>
          article && (
            <NavigationLink
              key={article.id}
              url={buildArticleUrl({
                url,
                article,
                category: { categoryId, categoryName },
              })}
              label={label}
              articleName={article?.name}
            />
          )
      )}
    </div>
  );
};

export default ArticleNavigationLinks;

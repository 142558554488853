const channel = [
  { value: 'seo_organic', label: 'Search - SEO/Organic' },
  { value: 'seo_local', label: 'Search - LocalFX/Maps' },
  { value: 'paid_search', label: 'Search - Paid' },
  { value: 'direct', label: 'Other - Cookies Blocked/Direct/Etc.' },
  { value: 'social_paid', label: 'Social - Paid' },
  { value: 'social_organic', label: 'Social - Organic' },
  {
    value: 'adtech_retargeting',
    label: 'AdTechFX - Enriched 1st Party Audience Data Programmatic Advertising',
  },
  { value: 'adtech_ads', label: 'AdTechFX - Rented Audience Data Programmatic Advertising' },
  { value: 'lead_nurture', label: 'Email - Email/SMS Marketing' },
  { value: 'referring', label: 'Referrals - Websites/Earned Media' },
  {
    value: 'referring_influencer',
    label: 'Partnerships - Influencer, Affiliate & Sponsored Content',
  },
];

const source = [
  { value: 'google', label: 'Google' },
  { value: 'bing', label: 'Bing' },
  { value: 'ask', label: 'Ask' },
  { value: 'yahoo', label: 'Yahoo' },
  { value: 'direct', label: 'Direct' },
  { value: 'gmb', label: 'GMB' },
  { value: 'geofencing', label: 'GeoFencing' },
  { value: 'linkedin', label: 'LinkedIn' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'other', label: 'Other' },
];

const medium = [
  { value: 'search', label: 'Search' },
  { value: 'organic', label: 'Organic' },
  { value: 'ppc', label: 'PPC' },
  { value: 'social', label: 'Social' },
  { value: 'referral', label: 'Referral' },
  { value: 'other', label: 'Other' },
];

const callStatus = [
  { value: 'completed', label: 'Completed' },
  { value: 'no-answer', label: 'No Answer' },
];

const touchPointStatus = [
  { value: 'uncategorized', label: 'Uncategorized' },
  { value: 'subscription', label: 'Subscription' },
  { value: 'new lead', label: 'New Lead' },
  { value: 'lead follow-up', label: 'Lead Follow-Up' },
  { value: 'spam', label: 'Spam' },
  { value: 'disqualified', label: 'Disqualified' },
  { value: 'mql', label: 'MQL' },
  { value: 'prospect', label: 'Prospect / SQL', storeValue: 'Prospect/SQL' },
  { value: 'sale', label: 'Closed Won' },
  { value: 'lost', label: 'Closed Lost' },
  { value: 'upsell request', label: 'Upsell Request' },
  {
    value: 'downgrade / cancellation request',
    label: 'Downgrade / Cancellation Request',
    storeValue: 'Downgrade/Cancellation Request',
  },
  { value: 'support / question', label: 'Support / Question', storeValue: 'Support/Question' },
];

const quality = [
  { value: 'good', label: 'Good' },
  { value: 'neutral', label: 'Neutral' },
  { value: 'poor', label: 'Poor' },
];

/**
 * Get Touchpoint DB value
 * @param {string} label
 * @param {boolean} nullable
 * @param {boolean} getLabel
 * @returns {string} -  string value of DB acceptable touchpoint
 */
const getTouchpointDBValue = (label, nullable = false, getLabel = false) => {
  label = label ? label.toLowerCase() : !nullable ? 'uncategorized' : null;
  return touchPointStatus.reduce((accum, touchpoint) => {
    if (
      label === touchpoint.label.toLowerCase() ||
      label === touchpoint.label.toLowerCase().replace(/\s/g, '') ||
      label === touchpoint.value.toLowerCase()
    ) {
      accum = getLabel ? touchpoint.label : touchpoint.value.toLowerCase();
    }
    return accum;
  }, '');
};

module.exports = {
  channel,
  medium,
  quality,
  source,
  callStatus,
  touchPointStatus,
  getTouchpointDBValue,
};

import { store } from '@webfx/core-web';

store.dispatch.router.registerRoutes([
  {
    name: 'signin',
    url: '/signin',
  },
  {
    name: 'forgotPassword',
    url: '/forgot-password',
  },
  {
    name: 'confirmPassword',
    url: '/confirm-password',
  },
  {
    name: 'myInfo',
    url: '/my-info',
  },
  {
    name: 'feedback',
    url: '/feedback',
  },
  {
    name: 'helpCenterArticle',
    url: '/help-center/category/:categoryId/:categoryName/article/:articleId/:articleName',
  },
  {
    name: 'helpCenterCategory',
    url: '/help-center/category/:categoryId/:categoryName',
  },
  {
    name: 'helpCenterSearch',
    url: '/help-center/search',
  },
  {
    name: 'helpCenterLegacy',
    url: '/help-center/:articleName',
  },
  {
    name: 'helpCenter',
    url: '/help-center',
  },
  {
    name: 'updateExpiredPassword',
    url: '/update-expired-password',
  },
]);

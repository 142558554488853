/**
 * Standard contact stages used by
 * - Customer360FX
 */
const getContactStageOptions = () => {
  // status options
  const statusOptions = [
    {
      label: 'Unknown',
      value: 'active',
      actualValue: 'unknown',
      isActive: false,
      dotColor: '#111111',
    },
    {
      label: 'Inactive',
      value: 'inactive',
      actualValue: 'inactive',
      isActive: false,
      dotColor: '#84919B',
    },
    {
      label: 'Subscriber',
      value: 'subscriber',
      actualValue: 'subscriber',
      isActive: false,
      dotColor: '#4a148c',
    },
    {
      label: 'Disqualified',
      value: 'disqualified',
      actualValue: 'disqualified',
      isActive: false,
      dotColor: '#374046',
    },
    { label: 'MQL', value: 'mql', actualValue: 'mql', isActive: false, dotColor: '#7CB342' },
    {
      label: 'SQL',
      value: 'prospect',
      actualValue: 'prospect',
      isActive: false,
      dotColor: '#F9A825',
    },
    { label: 'Lost', value: 'lost', actualValue: 'lost', isActive: false, dotColor: '#FFEB3B' },
    { label: 'Customer', value: 'sale', actualValue: 'sale', isActive: false, dotColor: '#4CAF50' }, // Sale
    {
      label: 'Churned',
      value: 'churned',
      actualValue: 'churned',
      isActive: false,
      dotColor: '#b71c1c',
    },
  ];

  // results
  return statusOptions;
};

module.exports = getContactStageOptions;

const { isString, startCase } = require('lodash');
/**
 *
 * @param {*} location
 * @param {*} operator
 * @param {*} variant
 * @returns
 */
function extractCountryRegion(location, operator = '$in', variant = 'location') {
  let countries = [];
  let regions = [];
  const parseLocation = (loc) => {
    if (isString(loc)) {
      countries.push(loc);
    }
    if (loc.$in || loc.$nin || Array.isArray(loc)) {
      // eslint-disable-next-line no-unused-vars -- used in the destructuring
      const [state, country] = loc.$in ?? loc.$nin ?? loc;
      regions.push(state);
    }
  };
  if (location.$in) {
    location.$in.forEach((loc) => {
      parseLocation(loc);
    });
  } else if (location.$nin) {
    location.$nin.forEach((loc) => {
      parseLocation(loc);
    });
  } else {
    parseLocation(location);
  }
  countries = [...new Set(countries)];
  regions = [...new Set(regions)];

  const hasCountry = countries.length > 0;
  const hasRegion = regions.length > 0;
  if (!hasCountry && !hasRegion) {
    return {};
  }

  let countryField, regionField;
  switch (variant) {
    case 'jobCompany':
      countryField = 'jobCompanyLocationCountry';
      regionField = 'jobCompanyLocationRegion';
      break;
    case 'kfCompanyLocation':
      countryField = 'kfCompanyCountry';
      regionField = 'kfCompanyRegion';
      break;
    default:
      countryField = 'locationCountry';
      regionField = 'locationRegion';
      break;
  }

  if (!hasRegion) {
    return {
      [countryField]:
        variant === 'kfCompanyLocation'
          ? { [operator]: countries.map((c) => startCase(c)) }
          : { [operator]: countries },
    };
  }

  if (!hasCountry) {
    return {
      [regionField]:
        variant === 'kfCompanyLocation'
          ? { [operator]: regions.map((r) => startCase(r)) }
          : { [operator]: regions },
    };
  }

  const locFilters = [
    {
      [countryField]:
        variant === 'kfCompanyLocation'
          ? { [operator]: countries.map((c) => startCase(c)) }
          : { [operator]: countries },
    },
    {
      [regionField]:
        variant === 'kfCompanyLocation'
          ? { [operator]: regions.map((r) => startCase(r)) }
          : { [operator]: regions },
    },
  ];
  return {
    $or: locFilters,
  };
}

module.exports = extractCountryRegion;

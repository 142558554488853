import { api } from '@webfx/core-web';

export default function useCopyPageStats({ companyId, siteId, forCGFX, showHiddenItems }) {
  const pageStats = api.useQuery(
    ['content/page-stats', { query: { companyId, siteId, forCGFX, showHiddenItems } }],
    {
      enabled: !!companyId || !!siteId,
      select: (data) => data.data ?? data ?? [],
      staleTime: 1000 * 60 * 5, // 5 min
      cacheTime: 1000 * 60 * 10, // 10 min
    }
  );

  return pageStats;
}

const hasIn = require('lodash/hasIn');

/**
 * Calculate marketing contact maximum value
 * @param {integer} planId
 * @param {boolean} returnDefault
 * @returns {integer} - maximum marketing contact value to use
 */
const getPlanMarketingContactsConfig = (planId = null, returnDefault = false) => {
  // default variables
  const defaultParams = {
    quantityName: 'quantityMarketingContacts',
    limitName: 'limitMarketingContacts',
    itemCode: 'ITEM-877',
    itemType: 'marketingContacts',
  };

  // marketing contact construct
  const maxPerPlan = {
    1: {
      max: 2000,
      quantity: 0,
      price: 0,
      quantityName: null,
      limitName: null,
      itemCode: null,
      itemType: null,
    },
    2: {
      max: 5000,
      quantity: 0,
      price: 0,
      quantityName: null,
      limitName: null,
      itemCode: null,
      itemType: null,
    },
    3: {
      ...defaultParams,
      max: 100000,
      quantity: 5000,
      price: 0.03,
    },
    4: {
      ...defaultParams,
      max: 500000,
      quantity: 10000,
      price: 0.02,
    },
    5: {
      ...defaultParams,
      max: 800000,
      quantity: 15000,
      price: 0.01,
    },
  };

  if (hasIn(maxPerPlan, planId)) {
    let item = maxPerPlan[planId];

    if (returnDefault && !item.itemType) {
      item = { ...item, ...defaultParams };
    }

    return item;
  }

  return {};
};

module.exports = getPlanMarketingContactsConfig;

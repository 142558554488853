import { Switch, Route, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';

import { useRouter } from '@webfx/web-hooks';

import Header from './Header';
import Categories from './Categories';
import CategoryPage from './Categories/CategoryPage';
import ArticlePage from './Articles/ArticlePage';
import SearchResults from './SearchResults';
import Footer from './Footer';

import styles from './HelpCenter.module.css';

const HelpCenter = () => {
  const { route } = useRouter();
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');
  const slug = new URLSearchParams(location.search).get('slug');
  const legacyMatch = useRouteMatch(route('helpCenterLegacy'));

  const legacySlug = legacyMatch?.isExact
    ? legacyMatch.params.articleName.split('-').slice(1).join('-')
    : null;

  return (
    <div className="bg-white">
      <Header />

      <div className={classnames(styles.pageContainer, 'm-auto min-vh-100')}>
        <Switch>
          <Route exact path={route('helpCenter')} component={Categories} />
          <Route exact path={route('helpCenterCategory')} component={CategoryPage} />
          <Route exact path={route('helpCenterArticle')} component={ArticlePage} />
          <Route
            exact
            path={route('helpCenterSearch')}
            render={({ location }) =>
              query || slug ? (
                <SearchResults
                  query={query || slug}
                  fromLegacy={location.state?.fromLegacy ?? false}
                />
              ) : (
                <Redirect to="/help-center" />
              )
            }
          />
          <Redirect
            exact
            path={route('helpCenterLegacy')}
            to={{
              pathname: route('helpCenterSearch'),
              search: `?slug=${legacySlug}`,
              state: { fromLegacy: true },
            }}
          />
        </Switch>
      </div>

      <Footer />
    </div>
  );
};

export default HelpCenter;

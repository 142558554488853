import classnames from 'classnames';

import { Icon, Avatar, Link } from '@webfx/core-web';
import { useRouter } from '@webfx/web-hooks';

import {
  buildArticleUrl,
  formatDate,
  truncateText,
} from '@webfx/common-web/src/ui/pages/HelpCenter/utils/utils.js';

import styles from './ArticleCard.module.css';

const ArticleCard = ({ category, article, fromMostRecent = false }) => {
  const { url } = useRouter();
  const person = article.lastModifiedBy;

  const name =
    person?.firstName && person?.lastName ? `${person.firstName} ${person.lastName}` : person.email;

  const date = formatDate(article.lastModified);

  const action = fromMostRecent ? 'Created' : 'Updated';

  return (
    <Link
      to={buildArticleUrl({ url, article, category })}
      className={classnames(styles.articleCardLink, 'text-gray-700 m-0')}
      data-fx-name="articleCard"
    >
      <div
        className={classnames(
          styles.articleCard,
          'd-flex flex-column h-100 border rounded text-hover-blue-vivid-500'
        )}
      >
        <div className="border-bottom">
          <div className="d-flex flex-column p-3 font-24">
            <h4 className={classnames(styles.preview, 'bold')} data-fx-name="articleName">
              {truncateText({ content: article.name, maxLength: 80 })}
            </h4>
            <div className="d-flex align-items-center text-gray-500 font-14">
              <Avatar src={person?.image} className={styles.avatar} />
              <span className="ml-2 semibold">{name}</span>
              <Icon className="font-18 ml-1">update</Icon>
              <span className="ml-1">{`${action} on ${date}`}</span>
            </div>
          </div>
        </div>
        <div className={classnames('p-3 text-gray-700', styles.preview)}>
          {truncateText({ content: article.content })}
        </div>
      </div>
    </Link>
  );
};

export default ArticleCard;

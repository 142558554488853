import { api } from '@webfx/core-web';
import { useRouter } from '@webfx/web-hooks';

/**
 * Web hook to get a category (folder) by ID.
 * @param {*} categoryId
 * @returns {object} The category.
 */
const useHelpCategory = () => {
  const {
    params: { categoryId },
  } = useRouter();

  return api.useQuery([`help-categories/${categoryId}`], {
    staleTime: 1000 * 60 * 15, // 15 min
    cacheTime: 1000 * 60 * 30, // 30 min
    enabled: !!categoryId,
  });
};

export default useHelpCategory;

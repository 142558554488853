import { useState } from 'react';
import classnames from 'classnames';

import { Image, Spinner } from '@webfx/core-web';

import office365Icon from '../../assets/office365-icon.png';
import styles from './Office365SignInButton.module.css';

/**
 *
 * @param root0
 * @param root0.isLoading
 * @param root0.clearErrors
 */
export function Office365SignInButton({ isLoading, clearErrors }) {
  const [onGetRedirectLink, setOnGetRedirectLink] = useState(false);

  const getRedirectUrl = () => {
    setOnGetRedirectLink(true);
    clearErrors();
    const tenant = 'common';
    const client_id = process.env.POI_APP_AZURE_MCFX_APP_CLIENT_ID;
    const response_type = 'code';
    const redirect_uri = `${process.env.POI_APP_URL}/signin?provider=office365`;
    const scope = 'User.Read';
    const queryParams = `client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&response_type=${response_type}&prompt=consent`;
    const office365AuthRedirectUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?${queryParams}`;
    window.location.replace(office365AuthRedirectUrl);
  };

  const showButtonText = !onGetRedirectLink;

  return (
    <>
      <button
        onClick={getRedirectUrl}
        type="button"
        className={classnames([
          'btn',
          styles.office365Button,
          'btn-outline-dark mb-2 d-flex mx-auto align-items-center',
        ])}
        disabled={isLoading || onGetRedirectLink}
      >
        <span className={classnames([styles.office365Icon])}>
          <Image className={classnames(['nav-svg'])} src={office365Icon} height="22px" />
        </span>
        {showButtonText && (
          <span className={classnames([styles.office365text])}>Sign in with Office 365</span>
        )}

        {onGetRedirectLink && (
          <Spinner className={classnames([styles.spinner])} animation="border" size="sm" />
        )}
      </button>
    </>
  );
}

import { useMemo } from 'react';
import { useDebounce } from 'use-debounce';

import useHelpArticles from './useHelpArticles';

const useHelpSuggestions = (query, debounceTime = 300) => {
  const [debouncedQuery] = useDebounce(query, debounceTime);

  const { data: articles, isFetching } = useHelpArticles({
    query: {
      searchTerms: debouncedQuery,
    },
    fromSearch: true,
  });

  const suggestions = useMemo(() => {
    if (!debouncedQuery) {
      return [];
    }

    return articles && articles.length > 0 ? articles : [];
  }, [debouncedQuery, articles]);

  return { suggestions, isFetching };
};

export default useHelpSuggestions;

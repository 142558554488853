// Icons
import LocalSEOIcon from '../../../../assets/images/help-center/categories/local-seo-icon.svg';
import EmailMarketingIcon from '../../../../assets/images/help-center/categories/email-marketing-icon.svg';
import MCFXIcon from '../../../../assets/images/help-center/categories/mcfx-icon.svg';
import PaidAdvertisingIcon from '../../../../assets/images/help-center/categories/paid-advertising-icon.svg';
import SEOIcon from '../../../../assets/images/help-center/categories/seo-icon.svg';
import SocialMediaIcon from '../../../../assets/images/help-center/categories/social-media-icon.svg';
import WebsiteIcon from '../../../../assets/images/help-center/categories/webdesign-icon.svg';
import SeminarsIcon from '../../../../assets/images/help-center/categories/seminars-icon.svg';
import CROIcon from '../../../../assets/images/help-center/categories/cro-icon.svg';
import NutshellIcon from '../../../../assets/images/help-center/categories/nutshell-icon.svg';
import AdtechIcon from '../../../../assets/images/help-center/categories/adtech-icon.svg';
import MROIReportingIcon from '../../../../assets/images/help-center/categories/mroi-reporting-icon.svg';
import PlaceholderIcon from '../../../../assets/images/help-center/categories/placeholder-icon.svg';

// Banners
import LocalSEOBanner from '../../../../assets/images/help-center/categories/local-seo-banner.svg';
import EmailMarketingBanner from '../../../../assets/images/help-center/categories/email-marketing-banner.svg';
import MCFXBanner from '../../../../assets/images/help-center/categories/mcfx-banner.svg';
import PaidAdvertisingBanner from '../../../../assets/images/help-center/categories/paid-advertising-banner.svg';
import SEOBanner from '../../../../assets/images/help-center/categories/seo-banner.svg';
import SocialMediaBanner from '../../../../assets/images/help-center/categories/social-media-banner.svg';
import WebsiteBanner from '../../../../assets/images/help-center/categories/webdesign-banner.svg';
import SeminarsBanner from '../../../../assets/images/help-center/categories/seminars-banner.svg';
import CROBanner from '../../../../assets/images/help-center/categories/cro-banner.svg';
import NutshellBanner from '../../../../assets/images/help-center/categories/nutshell-banner.svg';
import AdtechBanner from '../../../../assets/images/help-center/categories/adtech-banner.svg';
import MROIReportingBanner from '../../../../assets/images/help-center/categories/mroi-reporting-banner.svg';
import PlaceholderBanner from '../../../../assets/images/help-center/categories/placeholder-banner.svg';

/**
 * Mapping of the categories icons using the category slug as the key.
 */
export const categoriesMap = {
  'Website Design, Development and UX': {
    icon: WebsiteIcon,
    banner: WebsiteBanner,
  },
  'Social Media': {
    icon: SocialMediaIcon,
    banner: SocialMediaBanner,
  },
  SEO: {
    icon: SEOIcon,
    banner: SEOBanner,
  },
  'Paid Advertising': {
    icon: PaidAdvertisingIcon,
    banner: PaidAdvertisingBanner,
  },
  Nutshell: {
    icon: NutshellIcon,
    banner: NutshellBanner,
  },
  'Marketing ROI Reporting': {
    icon: MROIReportingIcon,
    banner: MROIReportingBanner,
  },
  MarketingCloudFX: {
    icon: MCFXIcon,
    banner: MCFXBanner,
  },
  'Local SEO': {
    icon: LocalSEOIcon,
    banner: LocalSEOBanner,
  },
  'Exclusive Client-Only Seminars': {
    icon: SeminarsIcon,
    banner: SeminarsBanner,
  },
  'Email Marketing': {
    icon: EmailMarketingIcon,
    banner: EmailMarketingBanner,
  },
  'Conversion Rate Optimization': {
    icon: CROIcon,
    banner: CROBanner,
  },
  AdTechFX: {
    icon: AdtechIcon,
    banner: AdtechBanner,
  },
  placeholder: {
    icon: PlaceholderIcon,
    banner: PlaceholderBanner,
  },
};

import { api } from '@webfx/core-web';

const useHelpCategories = () => {
  return api.useQuery(['help-categories'], {
    staleTime: 1000 * 60 * 15, // 15 min
    cacheTime: 1000 * 60 * 30, // 30 min
    placeholderData: [],
    select: (data) => data ?? [],
  });
};

export default useHelpCategories;

import { object } from 'yup';
import {
  email,
  notificationEmail,
  editPassword,
  addOldPassword,
  editPasswordConfirmation,
  firstName,
  lastName,
  title,
  officeNumber,
  ext,
  mobileNumber,
} from './validators';

export default object().shape({
  email,
  oldPassword: addOldPassword,
  password: editPassword,
  passwordConfirm: editPasswordConfirmation,
  firstName,
  lastName,
  title,
  officeNumber,
  ext,
  mobileNumber,
  notificationEmail,
});

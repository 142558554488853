import React, { useEffect, useRef, useState } from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Alert, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { api, Spinner } from '@webfx/core-web';
import useUser from '@webfx/permissions-web/src/hooks/useUser';
import timezones from 'timezones-list';

import {
  ActionBar,
  AddRemove,
  AvatarUploader,
  Button,
  FormikInput,
  FormikTextarea,
  InputLabel,
  SelectInput,
  SelectMenu,
  Typography,
  utils,
} from '@webfx/core-web';
import { useAuth, useNotifications } from '@webfx/web-hooks';
import classnames from 'classnames';
import validation from './validation';

import styles from './Profile.module.css';

const getFileUrl = (uploadId) => {
  let baseUrl;
  baseUrl = process.env.POI_APP_URL;
  return uploadId ? `${baseUrl}/uploads/${uploadId}` : '';
};

/**
 *
 */
const Profile = () => {
  const { user: authUser, isAdmin } = useAuth();
  const { userId } = authUser || {};
  const { data: user, isLoading } = useUser(userId);
  const emails = user?.emails;
  const mutator = api.useMutation(['users', userId]);
  const { toast } = useNotifications();
  const [files, setFiles] = useState([]);
  const pond = useRef();

  emails?.forEach((email) => {
    if (email.email === user.notificationEmail) {
      email.notification = true;
    } else {
      email.notification = false;
    }
  });

  // after we have a company initialize its image
  useEffect(() => {
    if (userId && user.avatarUrl) {
      setFiles([
        {
          source: user.avatarUrl,
          options: { type: 'local' },
        },
      ]);
    }
  }, [userId]);

  const onFilesUpdate = async (files) => {
    const { file } = files;
    const url = getFileUrl(file.uploadId);
    mutator.mutate({ _id: userId, _method: 'patch', avatarUrl: url });
    setFiles(files);
  };

  return (
    <Container fluid className={styles.containerFluid}>
      <div className={styles.avatarUploaderPlaceholder}>
        <AvatarUploader
          disabled={isAdmin}
          ref={pond}
          files={files}
          labelIdle='Drag & Drop your Profile Picture or <span class="filepond--label-action">Browse</span>'
          stylePanelLayout="compact circle"
          imagePreviewWidth={140}
          imagePreviewHeight={140}
          imageResizeTargetWidth={260}
          imageResizeTargetHeight={260}
          imageCropAspectRatio="1:1"
          styleLoadIndicatorPosition="center bottom"
          styleButtonRemoveItemPosition="center bottom"
          stylePanelAspectRatio="1:1"
          onupdatefiles={onFilesUpdate}
          imageUrl={user.avatarUrl}
          avatarData={{
            key: 'userId',
            value: user.userId,
          }}
        />
      </div>
      <Formik
        initialValues={{
          ...user,
          emails,
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={async (values, { setSubmitting }) => {
          const payload = {
            ...utils.dirtyProps(user, values, ['emails', 'avatarUrl']),
          };

          payload.emails.forEach((email) => {
            email.notification = !!email.notification;
            if (email.notification) {
              payload.notificationEmail = email.email;
            }
          });
          if (payload.emails.length == 1) {
            payload.notificationEmail = payload.emails[0].email;
          }

          if (
            !payload.emails
              .map((e) => {
                return e.email;
              })
              .includes(payload.email)
          ) {
            payload.email = payload.emails[0].email;
          }

          mutator.mutate(
            { _id: userId, _method: 'patch', ...payload },
            {
              onSuccess: () => {
                toast.success('Profile updated successfully');
              },
              onError: (error) => {
                toast.error(error.message);
              },
            }
          );
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <ActionBar>
              <Button
                type="submit"
                disabled={isSubmitting || isLoading}
                text="Save Changes"
                variant="primary"
                uppercase
                icon="done"
              />
            </ActionBar>
            {isLoading ? (
              <Spinner />
            ) : (
              <Row className={styles.formContentRow}>
                <div style={{ width: 166 }}></div>
                <Col md={9}>
                  <Typography className="mb-4 font-weight-bold font-12 text-gray-700">
                    Edit Profile
                  </Typography>

                  <Row className={styles.avatarRow}>
                    {isAdmin ? (
                      <Alert className={classnames('col-12', styles.alert)}>
                        Some fields are disabled for your user. Any changes for these fields should
                        be made in{' '}
                        <a
                          href="https://operationsfx.com/user/settings"
                          target="_blank"
                          rel="noreferrer"
                          className={styles.link}
                        >
                          OperationsFX
                        </a>
                      </Alert>
                    ) : null}
                    <Col md={6} className={styles.firstLastNames}>
                      <Field
                        disabled={isAdmin}
                        name="firstName"
                        component={FormikInput}
                        label="First Name"
                      />
                      <Field
                        disabled={isAdmin}
                        name="lastName"
                        component={FormikInput}
                        label="Last Name"
                      />
                      <Row>
                        <Col md={8}>
                          <Field
                            disabled={isAdmin}
                            name="calendar"
                            component={FormikInput}
                            label="Calendar Scheduling Link"
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            disabled={isAdmin}
                            name="pronouns"
                            component={FormikInput}
                            label="Pronouns"
                          />
                        </Col>
                      </Row>
                      <Field
                        disabled={isAdmin}
                        name="title"
                        component={FormikInput}
                        label="Title"
                      />
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={8}>
                          <Field
                            disabled={isAdmin}
                            name="officeNumber"
                            component={FormikInput}
                            label="Office #"
                          />
                        </Col>
                        <Col md={4}>
                          <Field
                            disabled={isAdmin}
                            name="ext"
                            component={FormikInput}
                            label="Ext"
                          />
                        </Col>
                      </Row>

                      <Field
                        disabled={isAdmin}
                        name="mobileNumber"
                        component={FormikInput}
                        label="Mobile #"
                      />
                      <div className="mb-4">
                        <Field
                          disabled={isAdmin}
                          name="timezone"
                          component={SelectInput}
                          label="Select Your Timezone"
                          getOptionLabel={(o) => o.label}
                          getOptionValue={(o) => o.tzCode}
                          options={timezones}
                          allowCustomValue={true}
                          optionLabel="tzCode"
                        />
                      </div>
                    </Col>
                  </Row>

                  <FieldArray
                    name="emails"
                    render={(fieldHelpers) => (
                      <div className="form-group mb-3" data-fx-name="emailsSection">
                        <InputLabel className="form-label" text="Emails" />
                        {(values.emails ?? []).map((email, index) => (
                          <div className="d-flex align-items-center mb-3" key={`email-${index}`}>
                            <div className={styles.toggle}>
                              <OverlayTrigger
                                overlay={(props) => (
                                  <Tooltip id={`primary-email-${index}-tooltip`} {...props}>
                                    Send notifications to this email
                                  </Tooltip>
                                )}
                              >
                                <div>
                                  <Button
                                    icon={
                                      email.notification ? 'notifications_active' : 'notifications'
                                    }
                                    className={classnames([
                                      styles.toggleBtn,
                                      {
                                        [styles.activeToggleBtn]: email.notification,
                                      },
                                    ])}
                                    onClick={() => {
                                      const update = values?.emails.map((e) => {
                                        e.notification = 0;
                                        return e;
                                      });

                                      update[index].notification = true;

                                      setFieldValue('emails', update);
                                    }}
                                  />
                                </div>
                              </OverlayTrigger>
                            </div>
                            <div className="flex-fill">
                              <div className="input-group">
                                <Field className="form-control" name={`emails[${index}].email`} />
                                <div className="input-group-append">
                                  <SelectMenu
                                    key={`email-type-select-${index}`}
                                    placeholder={'Type'}
                                    searchable={false}
                                    options={[
                                      { value: 'work', label: 'Work' },
                                      { value: 'personal', label: 'Personal' },
                                    ]}
                                    defaultValue={values.emails[index].type}
                                    onSelect={(type) => {
                                      fieldHelpers.replace(index, {
                                        ...values.emails[index],
                                        type,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <AddRemove
                              onAdd={() =>
                                fieldHelpers.insert(index + 1, {
                                  userId: user.userId,
                                  email: '',
                                  type: 'work',
                                })
                              }
                              onRemove={() => fieldHelpers.remove(index)}
                              hideAdd={values.emails.length >= 4}
                              hideRemove={values.emails.length < 2}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  />

                  <Field
                    name="wordsAboutYourself"
                    component={FormikTextarea}
                    label="About Me"
                    className="mb-3"
                  />
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Profile;

/**
 *  Get the main campaign type from the campaign type
 * @param {string} campaignType
 * @returns {string}
 */
const getMainCampaignType = (campaignType) => {
  switch (campaignType) {
    case 'ABM-AUD-CON':
    case 'ABM-AUD-COMP':
    case 'ABM-ICP-CON':
      return 'ABM';
    case 'ABMFX':
    case 'ABR-AUD-CON':
    case 'ABR-AUD-COMP':
    case 'ABR-ICP-CON':
      return 'ABR';
    case 'CRMRFX':
      return 'CRM';
    case 'AI':
      return 'AI';
    default:
      return '';
  }
};

module.exports = getMainCampaignType;

const sharedEmailRegex = require('./shared-email-regex');

/**
 * Validates if a string s a valid email address
 *
 * @param {*} email
 * @returns {boolean}
 */
function isEmail(email) {
  return String(email).toLowerCase().match(sharedEmailRegex);
}

module.exports = isEmail;

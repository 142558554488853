import classnames from 'classnames';
import { Redirect } from 'react-router';

import { useRouter, useDocumentTitle } from '@webfx/web-hooks';
import { Spinner, Link, Icon } from '@webfx/core-web';

import useHelpArticles from '@webfx/common-web/src/hooks/help-center/useHelpArticles.js';

import ResultCard from './ResultCard';

import styles from './SearchResults.module.css';

const SearchResults = ({ query, fromLegacy }) => {
  const { url } = useRouter();
  const { data: articles, isFetching } = useHelpArticles({
    query: {
      searchTerms: query,
    },
  });
  const slugQuery = new URLSearchParams(location.search).get('slug');

  useDocumentTitle(`Search Results For: ${query} | WebFX Client Knowledge Center`);

  if (isFetching) {
    return <Spinner />;
  }

  if (!articles || articles.length === 0) {
    return <span className="font-24 bold mb-3">No articles found for: {query}</span>;
  }

  // If this was a slug query, search for an article with a matching slug
  let articleSearched = articles.filter(
    (article) => article.slug.split('/')[1]?.toLowerCase() === slugQuery?.toLowerCase()
  );
  // If:
  // - Only one search result was found, or
  // - No exact match was found, but this was a redirect from a legacy URL
  // then set "articleSearched" to the first search result
  if ((!articleSearched.length && fromLegacy) || articles.length === 1) {
    articleSearched = [articles[0]];
  }

  // Redirect to the article page if only one article is found from the slug search
  if (articleSearched.length > 0) {
    const category = articleSearched?.[0]?.boards[0];

    const articleRoute = {
      route: 'helpCenterArticle',
      articleId: articleSearched[0].slug.split('/')[0],
      articleName: articleSearched[0].slug.split('/')[1],
      categoryId: category?.slug.split('/')[0],
      categoryName: category?.slug.split('/')[1],
    };
    return <Redirect to={url(articleRoute)} />;
  }

  return (
    <div className="d-flex flex-column">
      <Link to={url({ route: 'helpCenter' })} className="mb-4">
        <div className="d-flex align-items-center text-blue-vivid-500 font-16">
          <Icon className="font-18 mr-1">arrow_back</Icon>
          <span className={classnames(styles.linkLabel)}>Back to Client Knowledge Center</span>
        </div>
      </Link>
      <span className="font-24 bold mb-3">Search results for: {query}</span>
      {articles.map((article) => (
        <ResultCard key={article.id} article={article} />
      ))}
    </div>
  );
};

export default SearchResults;
